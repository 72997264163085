import styled from '@emotion/styled';

export default styled.div`
  label: page-report;
  font-family: Kanit;
  background: #fffaf5;
  padding: 20px 0;
  min-height: 100vh;
  .header {
    max-width: 400px;
    margin: auto;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    display: flex;
    align-items: center;
    h1 {
      font-weight: 500;
      font-size: 20px;
      color: #393f4d;
      margin-bottom: 8px;
    }
    p {
      margin: 0;
      font-weight: 300;
      font-size: 12px;
    }
    > svg {
      flex: 0 0 150px;
      position: relative;
      bottom: -10px;
    }
  }
  .container {
    max-width: 400px;
    color: #393f4d;
    position: relative;
    margin: auto;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    .section-form {
      background: #ffffff;
      box-shadow: 0px 2px 10px #fdebd6;
      border-radius: 20px;
      padding: 30px 20px;
      margin-bottom: 16px;
      > .title {
        font-weight: 500;
        font-size: 20px;
        color: #393f4d;
        margin-bottom: 10px;
      }
      > p {
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 20px;
      }
    }
    .button-submit {
      display: block;
      width: 100%;
      margin-top: 60px;
    }
    .ant-collapse {
      margin-bottom: 16px;
      border: none;

      > .ant-collapse-item {
        border: none;
        &.ant-collapse-item-active {
          border: none;
          border-radius: 0px;
          .ant-collapse-header {
            background-color: #ff937e;
            color: #fff;
          }
        }
      }
      .ant-collapse-content {
        background: #ffffff;
        border: 1px solid #ff937e;
        box-sizing: border-box;
        border-radius: 10px;
        margin-top: 8px;
      }
      .ant-collapse-header {
        position: relative;
        color: #393f4d;
        border: 1px solid #ff7a44;
        background-color: #fff;
        border-radius: 10px;
        > .anticon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .wrap-button {
      max-width: 310px;
      margin: auto;
      padding: 40px 0;
      button {
        width: 100%;
      }
    }
  }
`;
