import styled from '@emotion/styled';

export default styled.div`
  label: page-follow-up-case-detail;
  background: rgba(255, 209, 154, 0.1);
  font-family: Kanit;
  min-height: 100vh;
  padding: 20px 0;
  .wrap-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      flex: 1 1 auto;
    }
    > button {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      outline: none;
    }
  }
  .container {
    max-width: 400px;
    color: #393f4d;
    position: relative;
    margin: auto;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    ul.wrap-case {
      margin: 0;
      padding: 0;
      > li {
        margin-bottom: 16px;
        margin-left: 70px;
        @media (max-width: 425px) {
          margin-left: 50px;
        }
        color: #393f4d;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -30px;
          bottom: -18px;
          width: 2px;
          background: #ff967e;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          left: -43px;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
          background: #fff;
          border: 2px solid #ff967e;
          z-index: 3;
          border-radius: 100%;
          padding: 6px;
        }
        &:first-child {
          &:before {
            top: 50%;
          }
        }
        &:last-child {
          &:before {
            bottom: 50%;
          }
        }

        .wrap-content {
          background: #ffffff;
          box-shadow: 0px 2px 10px #ffe0c2;
          border-radius: 20px;
          padding: 20px;
          .title {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 6px;
          }
          .content {
            font-weight: 300;
            font-size: 16px;
            color: #393f4d;
          }
          p {
            font-weight: 300;
            font-size: 12px;
            color: rgba(57, 63, 77, 0.5);
            margin: 0;
          }
        }
        &.active {
          .wrap-content {
            opacity: 0.5;
          }
          &:after {
            content: url(/assets/icons/checked.svg);
            background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
            border: none;
          }
        }
        &.current {
          &:after {
            content: '';
            width: 14px;
            height: 14px;
            left: -36px;
            background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
            box-shadow: 0 0 0px 6px #fff, 0 0 0 8px #ff967e;
          }
          .wrap-content {
            opacity: 1;
            border: 2px solid #ff7a44;
            box-shadow: 0px 2px 10px #ffe0c2;
          }
        }
      }
    }
    .info {
      margin-top: 40px;
      background: #ffffff;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 20px;
      padding: 20px;
      h2 {
        margin-bottom: 20px;
      }
      ul.images {
        li {
          display: inline-block;
          margin-bottom: 10px;
          vertical-align: top;
          .media-preview > button.wrap-media {
            margin: 10px;
            @media (max-width: 375px) {
              max-width: 100%;
            }
          }
        }
      }
      .wrap-item {
        margin-bottom: 16px;
        margin-left: 16px;
      }
      .value {
        font-weight: 300;
        margin-top: 2px;
      }
      .card {
        margin-top: 40px;
        .title {
          border-bottom: 2px solid #e5e5e5;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: bold;
          padding-bottom: 6px;
        }
      }
    }
  }
`;
