import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Icon from './Icon';

import useLoading from '../../../hooks/loading';
import { onClosePage } from '../../../utils';

import Style from './style';

const RegisterUnverified = () => {
  const loading = useLoading();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    onClosePage();
  };

  return (
    <Style>
      <Header title="" onBack={handleBack} />
      <div className="container">
        <Icon />
        <h1>โปรดรอสักครู่</h1>
        <p>ข้อมูลของท่านกำลังอยู่ในขั้นตอนการตรวจสอบ</p>
        <div className="wrap-button">
          <Button primary size="lg" onClick={handleBack}>
            กลับ
          </Button>
        </div>
      </div>
    </Style>
  );
};

RegisterUnverified.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

RegisterUnverified.defaultProps = {};

export default RegisterUnverified;
