import React from 'react';

export default () => (
  <svg
    width="160"
    height="141"
    viewBox="0 0 160 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.2133 99.9045C17.2133 99.9045 9.68352 101.51 7.93255 101.184C6.18157 100.858 2.16007 100.109 1.12744 100.986L6.52792 103.417L10.838 104.415L15.86 103.046L17.2133 99.9045Z"
        fill="#FFBFB9"
      />
      <path
        d="M15.0201 100.365C15.0201 100.365 9.7736 104.766 8.11883 105.406C6.46406 106.046 2.635 107.434 2.18604 108.733L8.07393 108.035L12.2814 106.686L15.8795 102.931L15.0201 100.365Z"
        fill="#FFBFB9"
      />
      <path
        d="M62.726 136.349L31.9396 109.008C31.2295 108.378 30.3887 107.913 29.4772 107.645C28.5658 107.378 27.6063 107.314 26.6674 107.46C21.8763 108.202 10.8894 109.82 4.73212 110.422C4.34729 110.46 0.370725 109.865 0.370725 109.865C0.370725 109.865 -0.270669 109.603 0.319404 109.18C1.10189 108.65 0.229646 108.675 1.51883 107.863C4.16774 106.187 5.36712 106.871 7.48368 106.449C9.04436 106.093 10.48 105.324 11.6398 104.223C12.2812 103.583 12.2235 102.7 12.9226 102.387C14.4346 101.708 15.8889 100.908 17.2712 99.9942C19.4198 98.139 17.3482 97.5377 17.3482 97.5377C17.3482 97.5377 12.3133 96.2006 11.7296 95.9256C10.4469 95.2858 9.03583 92.4903 9.89529 92.0873C10.5944 91.761 15.7511 93.9296 19.6956 94.3263C21.754 94.5192 23.7611 95.0782 25.622 95.9767L34.1908 100.218L74.0721 123.977L62.726 136.349Z"
        fill="#FFBFB9"
      />
      <path
        d="M140.609 20.4709C140.856 23.6637 140.444 26.8731 139.397 29.9004C138.341 32.9619 136.419 35.6527 133.862 37.6474C132.574 38.625 131.164 39.4314 129.667 40.0463C128.159 40.6283 126.591 41.0424 124.991 41.2809C121.808 41.7164 118.577 41.6647 115.409 41.1274V41.0634C118.572 41.2941 121.75 41.161 124.882 40.6668L126.043 40.4557C126.428 40.3789 126.807 40.2638 127.185 40.1678C127.955 40.0015 128.686 39.7136 129.43 39.4769C130.895 38.9155 132.281 38.1672 133.554 37.2507C136.084 35.3501 138.009 32.7594 139.095 29.7916C140.207 26.8173 140.693 23.647 140.526 20.4774L140.609 20.4709Z"
        fill="#293D42"
      />
      <path
        d="M77.946 135.748C78.2095 137.432 78.0418 139.155 77.4585 140.757H120.085C118.071 136.068 114.723 129.242 114.402 127.406C113.235 120.702 140.5 89.4453 136.915 85.1016C133.329 80.7579 117.449 68.7632 117.449 68.7632L95.879 64.9249C95.879 64.9249 93.756 68.0276 92.5631 66.2811C91.3701 64.5347 79.2031 82.837 79.2031 82.837"
        fill="white"
      />
      <path
        d="M89.5422 36.0545C89.5422 36.0545 85.0525 38.2679 87.618 42.49C90.1835 46.7122 93.2109 47.0256 89.0483 51.9898C84.8857 56.954 91.9024 60.3061 93.7624 59.9671C93.7624 59.9671 87.0664 56.679 90.7608 53.1029C94.4551 49.5269 95.3082 48.401 90.2797 44.7162C85.2513 41.0315 90.9211 38.1975 90.9211 38.1975L91.3701 35.4979L89.5422 36.0545Z"
        fill="#1A2E35"
      />
      <path
        d="M119.559 42.0166C131.153 42.0166 140.551 32.6424 140.551 21.0787C140.551 9.51498 131.153 0.140747 119.559 0.140747C107.965 0.140747 98.5664 9.51498 98.5664 21.0787C98.5664 32.6424 107.965 42.0166 119.559 42.0166Z"
        fill="#1A2E35"
      />
      <path
        d="M111.606 33.9305C115.587 33.9305 118.815 30.7113 118.815 26.7401C118.815 22.7689 115.587 19.5497 111.606 19.5497C107.624 19.5497 104.396 22.7689 104.396 26.7401C104.396 30.7113 107.624 33.9305 111.606 33.9305Z"
        fill="#E46338"
      />
      <path
        d="M95.739 67.343C95.739 67.343 95.7176 67.3788 95.739 67.343V67.343Z"
        fill="#FFBDB7"
      />
      <path
        d="M95.9302 53.6594C96.206 54.651 96.0136 61.2592 95.8789 64.9504C95.8032 66.3139 95.5042 67.6559 94.9938 68.923C93.9612 71.6546 95.1926 78.2053 97.2322 79.8878C97.9698 80.5275 97.9249 86.7583 98.0981 86.7391C98.2713 86.72 101.106 80.8218 102.383 80.342C108.354 77.975 113.421 68.0338 113.421 68.0338L113.357 48.9319C113.357 48.9319 95.5454 52.2648 95.9302 53.6594Z"
        fill="#FFBDB7"
      />
      <path
        d="M113.421 52.1305V48.2922C113.421 48.2922 95.5069 52.3033 95.8853 53.6978C96.1611 54.6894 96.097 57.6065 95.9687 61.2977C95.9623 61.3361 97.9313 68.0147 113.421 52.1305Z"
        fill="#CB6F67"
      />
      <path
        d="M116.767 43.2071C118.693 33.6614 113.428 24.5527 105.007 22.8622C96.586 21.1716 88.1978 27.5395 86.2715 37.0852C84.3453 46.6308 89.6103 55.7396 98.0315 57.4301C106.453 59.1206 114.841 52.7528 116.767 43.2071Z"
        fill="#FFBDB7"
      />
      <path
        d="M94.853 44.9273C94.4311 44.9355 94.0128 44.8503 93.6279 44.6778C93.3527 44.5439 93.1092 44.3531 92.9135 44.1182C92.7178 43.8833 92.5743 43.6096 92.4927 43.3152C92.3785 43.0167 92.3342 42.6962 92.3631 42.378C92.392 42.0598 92.4934 41.7524 92.6595 41.4792C93.2349 40.7463 94.062 40.2518 94.9812 40.0911L95.892 32.5104C95.8967 32.4693 95.9097 32.4296 95.9301 32.3937C95.9506 32.3577 95.9781 32.3263 96.011 32.3012C96.044 32.2761 96.0817 32.2579 96.1218 32.2477C96.162 32.2375 96.2038 32.2354 96.2447 32.2417C96.3259 32.2529 96.3996 32.2953 96.4499 32.3598C96.5002 32.4243 96.5233 32.5059 96.5142 32.5872L95.5777 40.3917C95.5682 40.4592 95.5377 40.5221 95.4905 40.5714C95.4434 40.6207 95.3819 40.6541 95.3148 40.6668C95.3148 40.6668 93.7498 40.9099 93.1854 41.8119C93.0698 42.0097 93.001 42.2313 92.9843 42.4597C92.9677 42.6881 93.0035 42.9172 93.0892 43.1297C93.1446 43.3424 93.2456 43.5405 93.3851 43.7106C93.5246 43.8806 93.6993 44.0186 93.8973 44.1149C94.6562 44.3653 95.4784 44.3472 96.2255 44.0637C96.303 44.0344 96.3888 44.0369 96.4645 44.0704C96.5401 44.1039 96.5995 44.1659 96.6296 44.2428C96.644 44.2815 96.6505 44.3225 96.6489 44.3637C96.6474 44.4049 96.6376 44.4454 96.6204 44.4828C96.6031 44.5202 96.5786 44.5539 96.5483 44.5819C96.5179 44.6099 96.4824 44.6316 96.4436 44.6458C95.9321 44.8247 95.3949 44.9197 94.853 44.9273Z"
        fill="#1A2E35"
      />
      <path
        d="M97.0079 49.3094C95.7037 49.2894 94.4561 48.7747 93.5187 47.87C93.4845 47.8438 93.4561 47.8108 93.4352 47.7731C93.4143 47.7354 93.4014 47.6938 93.3974 47.6509C93.3933 47.608 93.3982 47.5648 93.4116 47.5239C93.4251 47.4829 93.4468 47.4452 93.4756 47.413C93.5043 47.3809 93.5393 47.355 93.5785 47.3369C93.6177 47.3189 93.6603 47.3091 93.7034 47.3081C93.7466 47.3072 93.7895 47.3151 93.8294 47.3315C93.8694 47.3478 93.9056 47.3722 93.9357 47.403C97.3157 50.3969 100.497 47.2751 100.632 47.1408C100.692 47.0909 100.769 47.0652 100.847 47.0688C100.925 47.0724 100.999 47.1051 101.055 47.1603C101.11 47.2155 101.143 47.2894 101.146 47.3674C101.15 47.4455 101.124 47.5221 101.074 47.5822C99.9668 48.6202 98.5257 49.2324 97.0079 49.3094Z"
        fill="#1A2E35"
      />
      <path
        d="M98.0276 51.6571C97.5188 51.6499 97.0209 51.5087 96.5845 51.2477C96.5483 51.2283 96.5164 51.2019 96.4906 51.1701C96.4649 51.1382 96.4457 51.1015 96.4344 51.0622C96.423 51.0229 96.4197 50.9816 96.4245 50.941C96.4294 50.9003 96.4423 50.8611 96.4626 50.8255C96.5043 50.7551 96.5713 50.7034 96.65 50.6808C96.7288 50.6582 96.8131 50.6665 96.8859 50.7039C97.1345 50.8498 97.4097 50.9453 97.6954 50.9847C97.9812 51.0241 98.2719 51.0067 98.5509 50.9336C98.8299 50.8604 99.0917 50.733 99.3211 50.5585C99.5505 50.3841 99.743 50.1661 99.8876 49.9171C99.9087 49.8826 99.9363 49.8527 99.969 49.8289C100.002 49.8051 100.039 49.7879 100.078 49.7784C100.117 49.7689 100.158 49.7672 100.198 49.7735C100.238 49.7797 100.276 49.7937 100.311 49.8147C100.382 49.8573 100.434 49.9263 100.454 50.0066C100.475 50.0869 100.462 50.172 100.42 50.2433C100.168 50.657 99.8185 51.0024 99.4013 51.249C98.984 51.4956 98.5121 51.6358 98.0276 51.6571Z"
        fill="#1A2E35"
      />
      <path
        d="M115.467 42.8802C115.467 42.8802 124.389 38.1335 123.805 46.0084C123.221 53.8833 114.742 53.8833 113.543 49.6292L115.467 42.8802Z"
        fill="#FFBDB7"
      />
      <path
        d="M115.666 47.7549C115.558 47.7557 115.455 47.7144 115.377 47.6397C115.305 47.5717 115.26 47.4802 115.249 47.3817C115.238 47.2832 115.263 47.1841 115.32 47.1024L115.961 46.1684C116.505 45.3597 117.218 44.6786 118.052 44.1725C119.187 43.4944 120.136 43.3664 120.874 43.795C122.221 44.5819 122.157 46.9553 122.157 47.0576C122.157 47.1128 122.146 47.1673 122.124 47.218C122.102 47.2686 122.07 47.3143 122.029 47.352C121.989 47.3898 121.941 47.4189 121.889 47.4376C121.837 47.4563 121.782 47.4641 121.727 47.4606C121.672 47.4607 121.618 47.4497 121.568 47.4282C121.517 47.4068 121.472 47.3754 121.434 47.3359C121.396 47.2964 121.367 47.2497 121.348 47.1985C121.329 47.1474 121.32 47.0929 121.323 47.0384C121.323 46.4883 121.201 44.9593 120.431 44.5115C119.982 44.2492 119.315 44.3772 118.462 44.889C117.967 45.184 117.521 45.5553 117.141 45.9893C117.607 45.7763 118.118 45.6818 118.629 45.7142C118.821 45.7367 119.007 45.7969 119.175 45.8913C119.343 45.9857 119.491 46.1125 119.61 46.2644C119.644 46.3073 119.669 46.3566 119.684 46.4094C119.699 46.4622 119.702 46.5174 119.695 46.5716C119.688 46.6259 119.67 46.6782 119.642 46.7254C119.614 46.7726 119.577 46.8138 119.533 46.8465C119.447 46.9141 119.336 46.9446 119.227 46.9314C119.118 46.9182 119.018 46.8624 118.95 46.7761C118.9 46.7096 118.836 46.6542 118.763 46.6134C118.69 46.5727 118.61 46.5475 118.526 46.5394C117.706 46.4371 116.346 47.3199 115.897 47.6717C115.83 47.7216 115.749 47.7505 115.666 47.7549Z"
        fill="#1A2E35"
      />
      <path
        d="M89.2088 29.9452C89.0292 30.2267 88.9395 30.3994 88.9395 30.3994L89.2088 29.9452Z"
        fill="#CB6F67"
      />
      <path
        d="M117.763 41.8311L116.955 42.2022V42.2341L115.493 42.8739C115.493 42.8739 114.794 38.8564 108.284 39.317C102.171 39.752 105.045 33.47 100.587 28.0388C95.7317 22.3517 90.3248 28.525 90.3248 28.525C90.3248 28.525 90.4018 28.621 90.3248 28.525C89.863 29.1072 89.4525 29.5805 89.2344 29.9388L89.8758 28.8577C81.8778 18.3727 99.9456 21.309 99.9456 21.309C99.9456 21.309 122.227 19.3003 117.763 41.8311Z"
        fill="#1A2E35"
      />
      <path
        d="M92.0501 22.2813C94.4104 22.3709 97.4506 23.484 100.151 27.5974C100.151 27.5974 95.6611 22.6715 90.2991 28.5313C87.7015 26.4011 88.6059 22.147 92.0501 22.2813Z"
        fill="#293D42"
      />
      <path
        d="M88.5736 42.0805C87.3389 42.0773 86.1318 41.7159 85.0995 41.0402C84.0671 40.3646 83.2542 39.404 82.7599 38.2755C82.2655 37.147 82.1109 35.8994 82.3152 34.6849C82.5194 33.4703 83.0736 32.3413 83.91 31.4354C84.7465 30.5296 85.8292 29.886 87.026 29.5833C88.2228 29.2805 89.4821 29.3317 90.6503 29.7305C91.8185 30.1292 92.8452 30.8584 93.605 31.8291C94.3649 32.7998 94.8252 33.9701 94.9297 35.1972C95.0701 36.8756 94.537 38.541 93.4476 39.8279C92.3582 41.1148 90.8013 41.918 89.1188 42.0613C88.9392 42.0741 88.7276 42.0805 88.5736 42.0805ZM88.5736 30.2138C88.4197 30.2138 88.2594 30.2138 88.1054 30.2138C87.0139 30.3038 85.9736 30.7147 85.1163 31.3947C84.2591 32.0746 83.6233 32.993 83.2895 34.0334C82.9558 35.0739 82.939 36.1898 83.2414 37.2398C83.5438 38.2898 84.1516 39.2267 84.9881 39.9319C85.8246 40.6372 86.852 41.0791 87.9404 41.2017C89.0287 41.3242 90.1291 41.122 91.1022 40.6205C92.0753 40.1191 92.8773 39.341 93.4068 38.3846C93.9363 37.4283 94.1694 36.3369 94.0767 35.2483C93.9581 33.8726 93.3276 32.5909 92.3091 31.6556C91.2906 30.7203 89.9581 30.1991 88.5736 30.1946V30.2138Z"
        fill="white"
      />
      <path
        d="M102.8 44.6714C101.563 44.6709 100.353 44.3111 99.3175 43.6361C98.2823 42.961 97.4665 41.9999 96.9698 40.8702C96.4731 39.7404 96.3169 38.4908 96.5204 37.274C96.7239 36.0572 97.2782 34.9258 98.1156 34.018C98.9531 33.1101 100.037 32.4651 101.236 32.1618C102.435 31.8584 103.697 31.9097 104.867 32.3095C106.037 32.7094 107.065 33.4403 107.825 34.4132C108.586 35.3861 109.046 36.5588 109.149 37.7881C109.22 38.619 109.125 39.4556 108.871 40.2501C108.617 41.0445 108.208 41.7813 107.669 42.4181C107.129 43.055 106.469 43.5796 105.726 43.9618C104.983 44.3441 104.171 44.5765 103.338 44.6459C103.159 44.664 102.979 44.6725 102.8 44.6714ZM102.8 32.8047H102.325C101.234 32.8959 100.194 33.3079 99.3378 33.9885C98.4815 34.6691 97.8468 35.5878 97.514 36.6283C97.1812 37.6688 97.1652 38.7843 97.4682 39.8338C97.7712 40.8833 98.3795 41.8196 99.2161 42.5242C100.053 43.2289 101.08 43.6701 102.168 43.7923C103.256 43.9144 104.356 43.7119 105.329 43.2104C106.302 42.7089 107.103 41.9308 107.633 40.9748C108.162 40.0187 108.395 38.9275 108.303 37.8393C108.188 36.4619 107.559 35.1777 106.54 34.2415C105.52 33.3053 104.185 32.7856 102.8 32.7855V32.8047Z"
        fill="white"
      />
      <path
        d="M94.4903 36.6296L94.2695 37.4316L96.6569 38.0853L96.8777 37.2833L94.4903 36.6296Z"
        fill="#A6D4FF"
      />
      <path
        d="M113.421 66.6713C113.77 67.6471 114.405 68.496 115.243 69.1083C116.081 69.7207 117.084 70.0684 118.122 70.1066C121.97 70.4456 121.438 70.7463 123.991 72.4223C126.543 74.0984 131.238 71.5011 134.49 81.1289L137.235 87.8843L117.449 91.294L113.292 90.5135L113.421 68.0339V66.6713Z"
        fill="white"
      />
      <path
        d="M96.8347 102.252C95.7123 102.252 94.7887 101.139 94.7759 99.783C94.7623 99.4311 94.8195 99.08 94.9439 98.7504C95.0684 98.4209 95.2577 98.1194 95.5006 97.8639C95.6664 97.6899 95.8651 97.5506 96.0853 97.4539C96.3056 97.3573 96.5429 97.3053 96.7834 97.3009C97.9123 97.3009 98.8359 98.414 98.8487 99.7702C98.8604 100.122 98.8025 100.473 98.6781 100.802C98.5537 101.131 98.3654 101.433 98.1239 101.689C97.7851 102.039 97.322 102.241 96.8347 102.252ZM96.7834 97.9534C96.623 97.9556 96.4647 97.9901 96.3181 98.055C96.1715 98.1199 96.0396 98.2137 95.9304 98.3309C95.7498 98.5247 95.6093 98.7522 95.5168 99.0003C95.4244 99.2484 95.3818 99.5122 95.3916 99.7766C95.3916 100.794 96.033 101.625 96.8283 101.625C96.9886 101.623 97.1466 101.588 97.2931 101.523C97.4397 101.458 97.5717 101.365 97.6814 101.248C98.0459 100.854 98.2395 100.332 98.2201 99.7958C98.2201 98.7851 97.5787 97.9534 96.7834 97.9534Z"
        fill="#1A2E35"
      />
      <path
        d="M96.3732 112.654C95.2507 112.654 94.3272 111.541 94.3144 110.191C94.3012 109.839 94.3585 109.488 94.4829 109.159C94.6074 108.829 94.7965 108.528 95.0391 108.272C95.3762 107.925 95.8374 107.725 96.3219 107.716C97.4443 107.716 98.3679 108.829 98.3807 110.178C98.3928 110.53 98.335 110.881 98.2106 111.21C98.0862 111.54 97.8977 111.841 97.6559 112.098C97.3188 112.445 96.8577 112.645 96.3732 112.654ZM96.3283 108.355C96.168 108.358 96.0099 108.393 95.8634 108.458C95.7169 108.523 95.5849 108.616 95.4753 108.733C95.1096 109.127 94.9138 109.648 94.9301 110.185C94.9301 111.202 95.5714 112.027 96.3732 112.034C96.5334 112.031 96.6915 111.996 96.838 111.931C96.9845 111.866 97.1165 111.773 97.2262 111.656C97.5918 111.262 97.7876 110.741 97.7714 110.204C97.7714 109.187 97.13 108.362 96.3283 108.355Z"
        fill="#1A2E35"
      />
      <path
        d="M103.05 8.09883C104.749 7.19688 106.513 6.4228 108.328 5.78306C110.139 5.13024 111.996 4.61067 113.883 4.22855C117.687 3.39625 121.629 3.40936 125.428 4.26693C127.325 4.71521 129.143 5.44632 130.822 6.43558C132.491 7.46147 134.003 8.72186 135.311 10.1779C137.786 13.1622 139.604 16.632 140.648 20.3622H140.584C139.256 16.7862 137.301 13.4742 134.811 10.5809C133.531 9.1978 132.08 7.98127 130.495 6.96014C128.877 5.98164 127.121 5.25063 125.287 4.7915C121.57 3.89811 117.702 3.82389 113.953 4.574C112.069 4.91293 110.213 5.38974 108.399 6.00056C106.578 6.59485 104.805 7.32623 103.095 8.18839L103.05 8.09883Z"
        fill="#BBBBBB"
      />
      <path
        d="M127.711 40.3533C128.998 38.1819 129.886 35.799 130.334 33.3164C130.553 32.0867 130.577 30.8304 130.405 29.5933C130.226 28.3692 129.767 27.2026 129.064 26.1836C128.34 25.1891 127.422 24.3513 126.364 23.7207L125.562 23.2665C125.28 23.1257 124.991 23.0106 124.716 22.8826C124.436 22.7441 124.147 22.6265 123.85 22.5308L122.971 22.2109C120.552 21.4953 118.069 21.0175 115.557 20.7844V20.7204C118.12 20.6431 120.681 20.9427 123.157 21.6096C124.404 21.9729 125.595 22.5045 126.697 23.1897C127.802 23.8967 128.751 24.8194 129.487 25.9021C130.202 26.9982 130.654 28.2437 130.809 29.5421C130.959 30.822 130.904 32.1174 130.648 33.3804C130.116 35.8793 129.138 38.2627 127.762 40.4173L127.711 40.3533Z"
        fill="#BBBBBB"
      />
      <path
        d="M100.914 21.9551C101.653 19.4957 102.77 17.1651 104.224 15.0462C105.654 12.8873 107.589 11.107 109.861 9.85806C112.19 8.66442 114.831 8.21756 117.423 8.57862C120.002 8.92682 122.488 9.76738 124.748 11.0543C125.873 11.7074 126.93 12.4702 127.903 13.3317C128.857 14.216 129.716 15.1957 130.469 16.2552C131.929 18.4143 132.951 20.8375 133.477 23.3881C133.972 25.9296 134.065 28.5328 133.753 31.1031C133.49 33.6722 132.697 36.1593 131.425 38.4086H131.373C131.943 37.2743 132.374 36.0759 132.656 34.839C132.972 33.609 133.187 32.3552 133.298 31.0903C133.523 28.5694 133.368 26.0291 132.836 23.5544C132.329 21.0841 131.342 18.7369 129.93 16.6455C129.218 15.6096 128.399 14.6512 127.487 13.7859C126.555 12.9346 125.541 12.1782 124.459 11.5277C122.272 10.2448 119.857 9.39542 117.346 9.02642C114.831 8.64529 112.259 9.04974 109.983 10.1843C107.728 11.3758 105.796 13.0945 104.352 15.1933C102.871 17.2769 101.724 19.5775 100.953 22.0127L100.914 21.9551Z"
        fill="#BBBBBB"
      />
      <path
        d="M114.742 41.3512C113.711 41.6415 112.777 42.2053 112.042 42.9825C111.686 43.3692 111.423 43.8317 111.272 44.3349C111.122 44.8382 111.089 45.3689 111.176 45.8868C111.374 46.9619 111.813 47.9786 112.459 48.8615L112.914 49.5524L113.446 50.1921C113.799 50.6143 114.152 51.043 114.53 51.4716C115.281 52.3223 115.911 53.2715 116.403 54.2927C116.914 55.3253 117.061 56.4995 116.82 57.6256C116.681 58.1743 116.487 58.7078 116.243 59.2185C115.975 59.7039 115.843 60.2515 115.858 60.805C115.973 61.3514 116.289 61.835 116.743 62.1612C117.181 62.5114 117.652 62.8199 118.148 63.0824C117.646 62.828 117.167 62.5305 116.717 62.1932C116.244 61.8675 115.91 61.3769 115.781 60.8178C115.751 60.2484 115.873 59.6812 116.134 59.1738C116.364 58.6662 116.542 58.1368 116.666 57.5937C116.874 56.5046 116.706 55.3775 116.191 54.3951C115.677 53.4058 115.03 52.4911 114.267 51.6763C113.895 51.2669 113.523 50.8446 113.151 50.3968L112.593 49.7571L112.106 49.0598C111.412 48.1366 110.934 47.0698 110.708 45.938C110.598 45.3499 110.622 44.7446 110.78 44.1675C110.938 43.5903 111.224 43.0562 111.618 42.6051C112.411 41.7228 113.432 41.0744 114.569 40.7307L114.742 41.3512Z"
        fill="#293D42"
      />
      <path
        d="M114.088 40.7372C114.088 40.7372 109.598 42.9506 112.164 47.1728C114.729 51.3949 117.75 51.7084 113.594 56.6726C109.438 61.6368 116.448 64.9889 118.308 64.6498C118.308 64.6498 111.612 61.3617 115.307 57.7857C119.001 54.2096 119.854 53.0837 114.826 49.399C109.797 45.7142 115.467 42.8803 115.467 42.8803L115.916 40.1807L114.088 40.7372Z"
        fill="#1A2E35"
      />
      <path
        d="M98.1689 21.1042C97.9166 17.9139 98.3271 14.706 99.3747 11.6812C100.426 8.61749 102.347 5.92408 104.903 3.92783C106.194 2.95112 107.606 2.14481 109.104 1.52889C110.613 0.946888 112.181 0.532826 113.78 0.294238C116.961 -0.141208 120.191 -0.089435 123.356 0.447769V0.511739C120.193 0.28122 117.015 0.414305 113.883 0.908366L112.722 1.11947C112.337 1.19624 111.965 1.31778 111.58 1.40734C110.82 1.60099 110.071 1.83374 109.335 2.10464C107.87 2.66399 106.484 3.41012 105.211 4.32445C102.681 6.2251 100.756 8.81574 99.6697 11.7835C98.5585 14.7579 98.0717 17.9281 98.2395 21.0978L98.1689 21.1042Z"
        fill="#293D42"
      />
      <path
        d="M89.7987 23.0426C89.677 23.9043 89.703 24.7804 89.8757 25.6335C89.9499 26.0416 90.1095 26.4296 90.3439 26.7722C90.6056 27.1163 90.9589 27.3801 91.3637 27.5334L90.9019 27.9556C90.479 27.7676 90.1361 27.4369 89.9334 27.0216C89.7189 26.6061 89.5922 26.151 89.5614 25.6846C89.4882 24.7969 89.5684 23.9032 89.7987 23.0426Z"
        fill="#1A2E35"
      />
      <path
        d="M90.9594 22.1342C90.8424 22.9985 90.8705 23.8763 91.0428 24.7314C91.1201 25.1376 91.2771 25.5245 91.5046 25.8701C91.7785 26.2317 92.1437 26.5143 92.5629 26.6889L92.0626 27.0152C91.641 26.8413 91.2969 26.5208 91.0941 26.1132C90.8809 25.6971 90.7544 25.2423 90.7221 24.7762C90.6524 23.8885 90.7326 22.9954 90.9594 22.1342Z"
        fill="#1A2E35"
      />
      <path
        d="M98.9448 21.4242C100.164 21.4823 101.344 21.8716 102.357 22.5501C103.369 23.2469 104.156 24.2228 104.621 25.3584C105.5 27.6358 105.641 30.1115 106.545 32.3249C106.775 32.8629 107.119 33.3451 107.552 33.7387C107.995 34.1188 108.523 34.3865 109.092 34.5192C109.376 34.5985 109.665 34.6583 109.957 34.6983C110.246 34.7367 110.56 34.807 110.868 34.8646C111.167 34.9478 111.461 35.0504 111.747 35.1717C112.016 35.3252 112.298 35.466 112.555 35.6451C113.527 36.3973 114.341 37.3345 114.947 38.4023C115.562 39.4492 116.036 40.5719 116.358 41.7416C115.954 40.609 115.432 39.522 114.8 38.4982C114.183 37.4786 113.368 36.5931 112.401 35.8946C112.157 35.7317 111.904 35.5822 111.644 35.4468C111.374 35.3346 111.098 35.2385 110.817 35.1589C110.528 35.1077 110.259 35.0373 109.945 34.999C109.639 34.96 109.337 34.8979 109.04 34.8134C108.429 34.6555 107.864 34.3552 107.392 33.937C106.938 33.5101 106.582 32.9908 106.346 32.4145C105.461 30.1179 105.359 27.655 104.525 25.3776C104.087 24.2591 103.334 23.2906 102.357 22.5885C101.366 21.8963 100.203 21.4891 98.9961 21.4114L98.9448 21.4242Z"
        fill="#BBBBBB"
      />
      <path
        d="M91.6566 39.2146C92.372 39.2019 92.9372 38.3669 92.9191 37.3496C92.9009 36.3322 92.3062 35.5178 91.5908 35.5305C90.8754 35.5432 90.3101 36.3782 90.3283 37.3956C90.3465 38.4129 90.9412 39.2273 91.6566 39.2146Z"
        fill="#1A2E35"
      />
      <path
        d="M100.177 40.1763C100.893 40.1636 101.458 39.3286 101.44 38.3112C101.421 37.2939 100.827 36.4794 100.111 36.4921C99.3959 36.5048 98.8306 37.3399 98.8488 38.3572C98.867 39.3746 99.4617 40.189 100.177 40.1763Z"
        fill="#1A2E35"
      />
      <path
        d="M115.544 86.0995C116.006 85.9652 115.005 86.1891 115.544 86.0995V86.0995Z"
        fill="#1A2E35"
      />
      <path
        d="M140.679 125.237L126.069 140.885L148.408 140.623L156.624 133.439C158.17 131.279 159.053 128.719 159.167 126.068C159.28 123.417 158.62 120.791 157.266 118.508C153.071 111.407 146.567 100.851 141.302 91.8634C138.582 87.2254 136.171 83.1312 134.522 80.3485C131.7 71.4052 124.004 72.4224 124.004 72.4224L116.493 85.9076C116.179 85.978 115.852 86.0484 115.544 86.0995L116.005 86.7904L92.0498 129.895L93.7815 140.77H121.188C121.188 140.77 113.305 128.295 114.845 124.732C116.185 121.649 125.107 107.754 127.454 104.101L140.679 125.237Z"
        fill="#FF937E"
      />
      <path
        d="M115.807 68.2385C115.807 68.2385 114.152 88.46 106.533 103.052C98.913 117.644 92.5762 128.967 92.5762 128.967L117.167 104.715L114.858 96.3029L124.004 72.4223L114.768 63.8821L115.807 68.2385Z"
        fill="#AA3C1E"
      />
      <path
        d="M35.6725 112.341C35.6725 112.341 27.5589 121.572 32.9594 109.929L35.6725 112.341Z"
        fill="#1A2E35"
      />
      <path
        d="M31.5806 115.814C31.5806 115.814 32.7543 115.635 35.6726 112.341L41.0473 104.024L77.4587 126.037C77.4587 126.037 81.9483 138.831 76.5222 140.751H64.3552L31.5806 115.814Z"
        fill="#FF937E"
      />
      <path
        d="M76.1436 140.757H93.7816L92.5758 129.453L84.1031 116.857L81.7492 93.3539L84.2635 69.7483C83.0705 70.1385 82.102 71.4307 81.3452 72.9469C81.3452 72.9469 70.5635 76.9515 72.2311 86.0931C72.5518 87.8779 72.9495 90.5711 73.3792 93.8209C73.3792 93.8209 62.264 108.713 74.662 117.107C76.2077 125.423 77.6893 133.842 77.6893 133.842C78.6385 139.887 76.1436 140.757 76.1436 140.757Z"
        fill="#FF937E"
      />
      <path
        d="M84.841 114.279C82.9073 104.037 83.0928 93.5098 85.3861 83.3423C86.4119 78.7272 87.8413 74.2106 89.6578 69.8443C90.1691 68.4175 91.0551 67.1538 92.2233 66.1851L95.9818 61.3361L81.4416 71.6163L78.0615 94.5694L74.04 97.64L83.5069 117.471C84.7289 120.026 86.3074 122.395 88.1954 124.508L92.4157 129.293C92.4157 129.293 91.6525 127.931 89.4204 124.719C87.1665 121.604 85.6053 118.044 84.841 114.279Z"
        fill="#AA3C1E"
      />
      <path
        d="M97.2323 79.8879C96.2801 77.2707 95.8445 74.4945 95.9495 71.7123L95.7315 61.5984L92.3065 66.7161C92.3065 66.7161 88.5351 80.8283 88.1118 83.1057C87.6885 85.3831 87.541 91.8826 87.541 91.8826L98.1046 81.7239L97.2323 79.8879Z"
        fill="#1A2E35"
      />
      <path
        d="M113.588 60.9266C113.588 60.9266 103.075 80.3804 100.683 81.711C101.225 82.5538 101.701 83.4371 102.107 84.3531L107.328 94.7741L112.324 80.7003L115.813 68.4625L113.588 60.9266Z"
        fill="#1A2E35"
      />
      <path
        d="M107.187 94.9212C107.109 94.9218 107.033 94.9004 106.967 94.8597C106.901 94.8189 106.849 94.7604 106.815 94.6909L100.683 82.6258L98.4702 86.9311C98.427 87.0134 98.3575 87.0789 98.2726 87.1172C98.1878 87.1554 98.0925 87.1643 98.002 87.1422C97.911 87.1231 97.8293 87.0732 97.7709 87.0009C97.7124 86.9286 97.6808 86.8385 97.6813 86.7456L97.6429 82.5811L87.8233 92.1768C87.7639 92.2337 87.6889 92.2717 87.6079 92.2862C87.5268 92.3006 87.4433 92.2908 87.3678 92.2579C87.2924 92.2251 87.2284 92.1707 87.1839 92.1016C87.1393 92.0325 87.1163 91.9519 87.1177 91.8697C87.1177 91.5819 87.2396 84.8329 87.8233 82.4275C88.4069 80.0222 91.864 66.7353 91.8961 66.6009C91.9087 66.5576 91.9282 66.5166 91.9538 66.4794L95.3788 61.3616C95.4303 61.2877 95.5039 61.2318 95.5891 61.202C95.6743 61.1721 95.7668 61.1698 95.8534 61.1953C95.9395 61.223 96.0143 61.278 96.0664 61.3519C96.1184 61.4257 96.145 61.5145 96.142 61.6047C96.0073 66.3003 96.142 78.9858 98.3484 81.308C98.4149 81.3847 98.4533 81.4817 98.4574 81.5831V85.0312L100.266 81.5063C100.316 81.413 100.398 81.3405 100.497 81.3016C101.664 80.873 107.443 70.6375 110.22 65.7181C113.171 60.4916 113.171 60.4916 113.53 60.4852C113.604 60.4855 113.677 60.5044 113.742 60.5401C113.807 60.5759 113.862 60.6274 113.902 60.6899C114.094 61.0162 115.403 65.6094 116.16 68.3218C116.176 68.3869 116.176 68.4549 116.16 68.5201C113.902 78.57 107.584 94.4798 107.52 94.6397C107.491 94.7142 107.442 94.7787 107.377 94.8253C107.312 94.8719 107.234 94.8986 107.155 94.902L107.187 94.9212ZM101.222 81.8518L107.135 93.4882C108.476 90.0529 113.414 77.1051 115.377 68.4625C114.781 66.345 113.895 63.236 113.453 61.835C112.927 62.7114 111.978 64.3939 110.984 66.1467C105.865 75.2243 102.678 80.5979 101.222 81.8518ZM92.6849 66.844C92.4156 67.8804 89.183 80.2781 88.6314 82.5875C88.2017 84.3723 88.0349 88.8119 87.99 90.827L97.4889 81.5575C95.2954 78.4421 95.2312 67.0487 95.2825 63.0057L92.6849 66.844Z"
        fill="#1A2E35"
      />
      <path
        d="M93.5316 114.516C93.4539 114.515 93.3792 114.486 93.3214 114.434C93.2636 114.382 93.2266 114.311 93.2173 114.234C93.2173 114.17 92.5054 107.402 92.5054 102.041C92.5054 97.3712 97.5979 87.0398 97.816 86.6048C97.8344 86.5677 97.86 86.5347 97.8913 86.5075C97.9226 86.4803 97.9589 86.4596 97.9983 86.4465C98.0376 86.4334 98.0792 86.4282 98.1206 86.4313C98.1619 86.4343 98.2023 86.4454 98.2393 86.4641C98.2763 86.4818 98.3093 86.5067 98.3365 86.5373C98.3636 86.5679 98.3844 86.6036 98.3976 86.6424C98.4107 86.6811 98.416 86.7221 98.413 86.7629C98.4101 86.8036 98.399 86.8434 98.3804 86.8799C98.3291 86.9822 93.1275 97.5184 93.1275 102.041C93.1275 107.37 93.833 114.1 93.8394 114.196C93.8484 114.278 93.8245 114.36 93.7729 114.425C93.7212 114.49 93.646 114.532 93.5636 114.541L93.5316 114.516Z"
        fill="#1A2E35"
      />
      <path
        d="M93.0377 137.213C91.9217 137.213 91.043 136.029 91.043 134.513C91.043 132.997 91.9217 131.807 93.0377 131.807C94.1537 131.807 95.0388 132.997 95.0388 134.513C95.0388 136.029 94.1601 137.213 93.0377 137.213ZM93.0377 132.639C92.3963 132.639 91.8768 133.496 91.8768 134.513C91.8768 135.53 92.4091 136.381 93.0377 136.381C93.6662 136.381 94.205 135.524 94.205 134.513C94.205 133.503 93.6726 132.639 93.0377 132.639Z"
        fill="#1A2E35"
      />
      <path
        d="M125.793 97.2242C125.722 97.2258 125.652 97.2023 125.596 97.1579C125.539 97.1136 125.5 97.051 125.486 96.9811C123.728 88.716 125.627 83.547 127.532 80.6491C128.672 78.8159 130.272 77.3119 132.175 76.2863C132.213 76.262 132.255 76.2461 132.3 76.2395C132.344 76.233 132.39 76.2361 132.433 76.2485C132.476 76.2609 132.516 76.2824 132.55 76.3115C132.584 76.3406 132.611 76.3767 132.63 76.4173C132.649 76.4579 132.659 76.5021 132.66 76.5468C132.66 76.5916 132.651 76.6359 132.633 76.6769C132.615 76.7178 132.588 76.7544 132.554 76.7841C132.521 76.8138 132.481 76.836 132.438 76.8492C132.342 76.894 122.817 81.5063 126.101 96.8468C126.11 96.8871 126.11 96.9287 126.102 96.9692C126.095 97.0096 126.079 97.0482 126.057 97.0827C126.034 97.1171 126.005 97.1468 125.971 97.17C125.936 97.1932 125.898 97.2094 125.858 97.2178L125.793 97.2242Z"
        fill="#1A2E35"
      />
      <path
        d="M74.0207 140.994C73.9885 140.995 73.9563 140.989 73.9264 140.977C73.8966 140.965 73.8697 140.947 73.8476 140.923C73.8249 140.901 73.8068 140.875 73.7945 140.846C73.7822 140.817 73.7759 140.785 73.7759 140.754C73.7759 140.722 73.7822 140.691 73.7945 140.662C73.8068 140.633 73.8249 140.606 73.8476 140.584C76.8685 137.641 75.8679 125.384 75.8551 125.231C75.8525 125.2 75.856 125.169 75.8656 125.139C75.8751 125.109 75.8905 125.082 75.9108 125.058C75.931 125.034 75.9558 125.014 75.9837 125C76.0116 124.986 76.042 124.977 76.0732 124.975C76.105 124.971 76.1374 124.973 76.1684 124.981C76.1993 124.99 76.2281 125.005 76.2529 125.026C76.2776 125.046 76.2978 125.072 76.3122 125.1C76.3265 125.129 76.3347 125.161 76.3361 125.193C76.381 125.704 77.3624 137.808 74.1811 140.904C74.1617 140.929 74.1375 140.95 74.11 140.965C74.0824 140.98 74.0521 140.99 74.0207 140.994Z"
        fill="#1A2E35"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="159.166"
          height="141"
          fill="white"
          transform="translate(0.0307617)"
        />
      </clipPath>
    </defs>
  </svg>
);
