import jwt from 'jsonwebtoken';

import * as ActionType from './actionType';
import { getAccessToken } from '../service';

export const renewTokenSuccess = ({ accessToken, authUser }) => ({
  type: ActionType.RENEW_TOKEN_SUCCESS,
  data: {
    accessToken,
    authUser,
  },
});

let renewTimer;
const refreshTokenInterval = process.env.REACT_APP_RENEW_TOKEN_INTERVAL || 4 * 60 * 1000;
// const refreshTokenInterval = process.env.REACT_APP_RENEW_TOKEN_INTERVAL || 5000;

const logoutReceived = () => {
  if (renewTimer) clearInterval(renewTimer);

  return {
    type: ActionType.USER_LOGOUT_RECEIVED,
  };
};

export function renewTokenDispatcher() {
  return async (dispatch) => {
    console.log('renewTokenDispatcher >>>', refreshTokenInterval);
    if (!renewTimer) {
      renewTimer = setInterval(async () => {
        try {
          console.log(
            'TODO: fetching renewToken api at ',
            new Date().toString()
          );
          const json = await getAccessToken();
          const { accessToken } = json.data;
          const authUser = jwt.decode(accessToken);
          dispatch(renewTokenSuccess({ accessToken, authUser }));
        } catch (error) {
          console.log(error);
          // dispatch(logoutReceived());
        }
      }, refreshTokenInterval);
    }
  };
}

export const appReady = (profile) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.INITAPP,
      profile
    });
  };
};

export const loginSuccess = (authUser) => {
  return (dispatch) => {
    dispatch({
      type: ActionType.USER_LOGIN_SUCCESS,
      authUser
    });
    dispatch(renewTokenDispatcher());
  };
};

export const logoutSuccess = () => {
  return (dispatch) => {
    dispatch(logoutReceived());
  };
};
