import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Header from '../Header';
import GoogleMap from './Map';

import Style from './style';

const InputGoogleMap = (props) => {
  const { onChange, value } = props;
  const [isOpenMap, setIsOpenMap] = React.useState(false);

  const handleMapChange = (data) => {
    setIsOpenMap(false);
    onChange({
      lat: data.lat,
      lng: data.lng
    });
  };

  return (
    <Style>
      {!isEmpty(value) && (
        <>
          <GoogleMap
            value={value}
            defaultCenter={value}
            disabled
            mapStyle={{ height: '100px', width: '268px' }}
          />
          <div className="value">
            {`${get(value, 'lat', 0)}, ${get(value, 'lng', 0)}`}
          </div>
        </>
      )}
      <button
        type="button"
        onClick={() => {
          setIsOpenMap(true);
        }}
      >
        เลือกตำแหน่งจากแผนที่
      </button>
      <div className={`wrap-map ${isOpenMap ? 'active' : ''}`}>
        <Header
          title="รายละเอียดที่อยู่"
          onBack={() => {
            setIsOpenMap(false);
          }}
        />
        {isOpenMap && (
          <GoogleMap
            onChange={handleMapChange}
            value={value}
            defaultCenter={value}
            mapStyle={{ height: 'calc(100vh - 68px)', width: '100vw' }}
          />
        )}
      </div>
    </Style>
  );
};

InputGoogleMap.propTypes = {
  value: PropTypes.shape(),
  onChange: PropTypes.func
};

InputGoogleMap.defaultProps = {
  value: undefined,
  onChange: () => null
};

export default InputGoogleMap;
