import styled from '@emotion/styled';

export default styled.div`
  label: page-follow-up-add-content;
  background: rgba(255, 209, 154, 0.1);
  padding: 20px 0;
  min-height: 100vh;
  .container {
    max-width: 400px;
    color: #393f4d;
    position: relative;
    margin: auto;
    font-family: Kanit;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    h1 {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 20px;
    }
    .wrap-button {
      text-align: center;
      margin-top: 16px;
    }
  }
`;
