import styled from '@emotion/styled';

export default styled.header`
  label: component-header;
  height: 68px;
  .wrap-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 68px;
    padding: 0 20px;
    background: #fff;
    display: flex;
    align-items: center;
    z-index: 90;
    > button {
      background: none;
      border: none;
      outline: none;
    }
    > .title {
      font-family: Kanit;
      flex: 1 1 auto;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
    }
  }
`;
