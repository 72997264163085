import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Icon from './Icon';

import useLoading from '../../../hooks/loading';

import Style from './style';

const ReportSuccess = (props) => {
  const { history } = props;
  const loading = useLoading();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    history.push('/reports');
  };

  return (
    <Style>
      <Header title="แจ้งเหตุสำเร็จ" onBack={handleBack} />
      <div className="container">
        <Icon />
        <h1>ขอบคุณสำหรับการแจ้งเหตุ</h1>
        <p>
          เจ้าหน้าที่ได้รับข้อมูลที่แจ้งและดำเนินการตรวจสอบเป็นที่เรียบร้อยแล้ว
        </p>
        <p>สามารถติดตามความคืบหน้าได้จาก Rich Menu ติดตามเคส </p>
        <div className="wrap-button">
          <Button primary size="lg" onClick={handleBack}>
            กลับ
          </Button>
        </div>
      </div>
    </Style>
  );
};

ReportSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

ReportSuccess.defaultProps = {};

export default ReportSuccess;
