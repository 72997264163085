import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import toString from 'lodash/toString';
import size from 'lodash/size';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { DatePicker, Spin } from 'antd';
import { getStatisticProvince } from '../../../service';
import IconThai from './Icon';

import Style from './style';

const Country = ({ year, onChange }) => {
  const [currentZone, setCurrentZone] = React.useState([]);
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const res = await getStatisticProvince({ year });
      const groupData = groupBy(get(res, 'data'), 'regionId');
      const activeIds = map(groupData, (value, index) => index);
      setCurrentZone(activeIds);
      setState(groupData);
    } catch (err) {
      console.log('error', err);
    }
    setLoading(false);
  };

  const handleSelectZone = (id) => {
    if (includes(currentZone, id)) {
      setCurrentZone(
        filter(currentZone, (key) => toString(key) !== toString(id))
      );
    } else {
      setCurrentZone([...currentZone, id]);
    }
  };

  const handleDateChange = (value) => {
    onChange(value.format('YYYY'));
  };

  React.useEffect(() => {
    loadData();
  }, [year]); // eslint-disable-line

  const items = filter(state, (values, key) => includes(currentZone, key));

  const total = reduce(
    items,
    (pre, values) => {
      return (
        pre + reduce(
          values,
          (sum, item) => {
            return sum + item.totalIncident;
          },
          0
        )
      );
    },
    0
  );

  return (
    <Style>
      {loading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      <div className="select-date">
        <DatePicker
          value={moment(year, 'YYYY')}
          picker="year"
          format="ปี ค.ศ. YYYY"
          onChange={handleDateChange}
        />
      </div>
      <div className="wrap-icon">
        <IconThai activeIds={currentZone} onSelect={handleSelectZone} />
      </div>
      {size(items) > 0 && (
        <div className="items-list">
          <div className="title">{total} ราย</div>
          <ul>
            {map(items, (values) => (
              <>
                <li key={get(values, '0.regionName', '')}>
                  <b>{get(values, '0.regionName', '')}</b>
                </li>
                {map(values || [], (item, index) => (
                  <li key={index}>
                    <span>{item.provinceName}</span>
                    <span>{`${item.totalIncident} ราย`}</span>
                  </li>
                ))}
              </>
            ))}
          </ul>
        </div>
      )}
    </Style>
  );
};

Country.propTypes = {
  onChange: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired
};

Country.defaultProps = {};

export default Country;
