import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Icon from './Icon';

import useLoading from '../../../hooks/loading';
import { onClosePage } from '../../../utils';

import Style from './style';

const PageRegisterSuccess = () => {
  const loading = useLoading();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    onClosePage();
  };

  return (
    <Style>
      <Header title="ลงทะเบียนสำร็จ" onBack={handleBack} />
      <div className="container">
        <Icon />
        <p>
          ข้อมูลของท่านอยู่ในขั้นตอนการตรวจสอบ เราจะแจ้งผลการลงทะเบียนไปยังแชท
          ภายใน 30 นาที
        </p>
        <div className="wrap-button">
          <Button primary size="lg" onClick={handleBack}>
            เสร็จสิ้น
          </Button>
        </div>
      </div>
    </Style>
  );
};

PageRegisterSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

PageRegisterSuccess.defaultProps = {};

export default PageRegisterSuccess;
