import styled from '@emotion/styled';

export default styled.div`
  label: component-input-google-map;
  text-align: center;
  > button {
    background: #fff4ea;
    border: 1px solid #ea6338;
    box-sizing: border-box;
    border-radius: 15px;
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    padding: 6px 20px;
    color: #ea6338;
  }
  > .value {
    background: #f2f6fb;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    font-weight: 300;
    font-size: 16px;
  }
  > .wrap-map {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const StyleGoogleMap = styled.div`
  label: component-google-map;
  overflow: hidden;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  &.disabled {
    pointer-events: none;
  }
  .wrap-autocomplete {
    position: absolute;
    z-index: 99;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    @media (max-width: 425px) {
      width: calc(100vw - 40px);
    }
    .wrap-input {
      position: relative;
      box-shadow: 0px 2px 10px #ffe0c2;
      background: #ffffff;
      border-radius: 30px;
      > .icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      > .loading {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      input {
        height: 40px;
        width: 100%;
        padding: 4px 40px;
        border: none;
        outline: none;
        border-radius: 30px;
      }
    }
    .suggestions {
      background: #fff;
      margin-top: 6px;
      border-radius: 10px;
      ul {
        max-height: 300px;
        overflow: auto;
        li {
          border-bottom: 1px solid #a6d4ff;
          padding: 4px 10px;
          cursor: pointer;
          &:last-child {
            border-bottom: none;
          }
          span {
            display: block;
            text-align: left;
            font-weight: 300;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .mainText {
            font-weight: normal;
          }
        }
      }
    }
  }
  > .group-button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    button {
      width: 310px;
      text-align: center;
    }
  }
  .marker {
    width: 20px;
    height: 20px;
    transform: translate(-10px, -30px);
    color: #ff7a44;
  }
`;
