import styled from '@emotion/styled';

export default styled.div`
  label: page-condition;
  background: #fff;
  font-family: Kanit;
  > .container {
    max-width: 370px;
    color: #393f4d;
    position: relative;
    margin: auto;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    .condition {
      max-height: 460px;
      overflow: auto;
      margin: 20px 0;
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;

      color: #393f4d;
    }
    .wrap-button {
      max-width: 310px;
      margin: auto;
      padding: 40px 0;
    }
    button {
      width: 100%;
    }
  }
`;
