import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Spin } from 'antd';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import {
  getStatisticGenderMonth,
  getStatisticGenderYear
} from '../../../service';
import { getMonthName } from '../../../utils';

import Style from './style';

const CustomizedAxisTick = (props) => {
  // eslint-disable-next-line react/prop-types
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="rgba(57, 63, 77, 0.5)"
        transform="rotate(-45)"
      >
        {get(payload, 'value')}
      </text>
    </g>
  );
};

const CompareChart = ({ year }) => {
  const [currentType, setCurrentType] = React.useState('month');
  const [state, setState] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const loadData = async (searchType) => {
    setLoading(true);
    try {
      let data = [];
      setCurrentType(searchType);
      if (searchType === 'month') {
        const resMonth = await getStatisticGenderMonth({ year });
        data = map(groupBy(get(resMonth, 'data', []), 'month'), (values) => {
          return {
            index: get(values, '0.month'),
            name: getMonthName(get(values, '0.month')),
            female: get(
              find(values, (value) => value.gender === 'female'),
              'totalIncident',
              0
            ),
            male: get(
              find(values, (value) => value.gender === 'male'),
              'totalIncident',
              0
            ),
            other: get(
              find(values, (value) => value.gender === 'other'),
              'totalIncident',
              0
            )
          };
        });
      } else {
        const resYear = await getStatisticGenderYear();
        data = map(groupBy(get(resYear, 'data', []), 'year'), (values) => {
          return {
            index: get(values, '0.year'),
            name: get(values, '0.year'),
            female: get(
              find(values, (value) => value.gender === 'female'),
              'totalIncident',
              0
            ),
            male: get(
              find(values, (value) => value.gender === 'male'),
              'totalIncident',
              0
            ),
            other: get(
              find(values, (value) => value.gender === 'other'),
              'totalIncident',
              0
            )
          };
        });
      }

      setState(orderBy(data, ['index'], ['asc']));
    } catch (err) {
      console.log('error', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadData('month');
  }, [year]); // eslint-disable-line

  const handleTypeChange = (e) => {
    loadData(e.target.value);
  };

  return (
    <Style>
      {loading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      <div className="wrap-hrader-chart">
        <div className="title">เปรียบเทียบตลอดปี</div>
        <div className="wrap-type">
          <Radio.Group
            options={[
              { label: 'เดือน', value: 'month' },
              { label: 'ปี', value: 'year' }
            ]}
            onChange={handleTypeChange}
            value={currentType}
            optionType="button"
          />
        </div>
      </div>
      <div className="wrap-chart" style={{ width: '100%', height: '300px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={state}
            margin={{
              top: 20,
              bottom: 5
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              height={60}
              interval={0}
              tick={
                currentType === 'year' ? (
                  { fill: 'rgba(57, 63, 77, 0.5)' }
                ) : (
                  <CustomizedAxisTick />
                )
              }
            />
            <YAxis axisLine={false} tickLine={false} />
            <Bar
              dataKey="female"
              name="Female"
              maxBarSize={10}
              stackId="a"
              fill="#FF937E"
            />
            <Bar
              dataKey="male"
              name="Male"
              maxBarSize={10}
              stackId="a"
              fill="#FFD56A"
            />
            <Bar
              dataKey="other"
              name="Other"
              maxBarSize={10}
              stackId="a"
              fill="#A6D4FF"
            />
            <Tooltip />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="legend">
        <ul>
          <li className="female">Female</li>
          <li className="male">Male</li>
          <li className="other">Other</li>
        </ul>
      </div>
    </Style>
  );
};

CompareChart.propTypes = {
  year: PropTypes.string.isRequired
};

CompareChart.defaultProps = {};

export default CompareChart;
