/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import { Form, Input, DatePicker } from 'antd';
import { useSelector } from 'react-redux';

import Header from '../../components/Header';
import Image from '../../components/Image';
import Button from '../../components/Button';

import useLoading from '../../hooks/loading';
import { getThaiDate, dateToFormat, onClosePage, alert } from '../../utils';
import { getIncidents, getMyIncidents } from '../../service';

import Style from './style';

const IconSearch = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.857 4.10707C5.39145 4.10707 3.39272 6.1058 3.39272 8.57136C3.39272 11.0369 5.39145 13.0356 7.857 13.0356C10.3226 13.0356 12.3213 11.0369 12.3213 8.57136C12.3213 6.1058 10.3226 4.10707 7.857 4.10707ZM2.32129 8.57136C2.32129 5.51407 4.79971 3.03564 7.857 3.03564C10.9143 3.03564 13.3927 5.51407 13.3927 8.57136C13.3927 11.6286 10.9143 14.1071 7.857 14.1071C4.79971 14.1071 2.32129 11.6286 2.32129 8.57136Z"
      fill="#393F4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.05 12.1213C11.2592 11.9121 11.5984 11.9121 11.8076 12.1213L16.4504 16.7641C16.6597 16.9733 16.6597 17.3125 16.4504 17.5217C16.2412 17.7309 15.902 17.7309 15.6928 17.5217L11.05 12.8789C10.8408 12.6697 10.8408 12.3305 11.05 12.1213Z"
      fill="#393F4D"
    />
  </svg>
);

const FollowUp = (props) => {
  const { history } = props;
  const loading = useLoading();
  const [form] = Form.useForm();
  const [state, setState] = React.useState([]);
  const roleIds = useSelector(({ user }) => get(user, 'authUser.roleIds', []));
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);

  const loadData = async (query = {}) => {
    loading.open();
    try {
      const body = {
        offset: 0,
        limit: 10,
        ...query
      };
      let res = [];

      if (includes(roleIds, 3) || includes(roleIds, 6)) {
        res = await getMyIncidents(body);
      } else {
        res = await getIncidents(body);
      }

      const data = get(res, 'data', []);
      if (isEmpty(data)) {
        // ถ้า init page แล้วไม่มี data ให้
        history.push('/follow-up/no-case');
      } else {
        setState(data);
      }
    } catch (err) {
      console.log('loadData >>', err);
      alert.error(get(err, 'errorMessage', err));
      history.push('/follow-up/no-case');
    }
    setTimeout(() => {
      loading.close();
    }, 500);
  };

  React.useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  const handleSearch = (values) => {
    loadData({
      keyword: values.keyword,
      createdAtFrom: dateToFormat(values.createdAtFrom),
      createdAtTo: dateToFormat(values.createdAtTo)
    });
    setIsOpenFilter(false);
  };

  const handleBack = () => {
    onClosePage();
  };

  const renderStatus = (data = {}) => {
    return (
      <span className={`status case-${data.status}`}>{data.statusText}</span>
    );
  };

  const titleHeader = (
    <div className="wrap-title">
      <span>ติดตามเคส</span>
      <button
        onClick={() => {
          setIsOpenFilter(true);
        }}
      >
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 1.66675H1L9 11.1267V20.0001L13 22.0001V11.1267L21 1.66675Z"
            stroke="#393F4D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );

  return (
    <Style>
      <Header title={titleHeader} onBack={handleBack} />
      <div className="container">
        <ul className="wrap-case">
          {map(state, (data) => {
            const { id, profile, createdAt, updatedAt, caseNumber } = data;
            return (
              <li key={id}>
                <Link
                  onClick={() => {
                    loading.open();
                    // แสดง loading ก่อนค่อยเปลียนหน้า
                    setTimeout(() => {
                      history.push(`/follow-up/${id}/detail`);
                    }, 300);
                  }}
                >
                  <div className="wrap-image">
                    <Image src={profile} alt="" />
                  </div>
                  <div className="info">
                    <div className="title">ลำดับเคส {caseNumber}</div>
                    <p>วันที่แจ้ง : {getThaiDate(createdAt)}</p>
                    <p>อัปเดตเคสล่าสุด : {getThaiDate(updatedAt)}</p>
                    {renderStatus(data)}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`wrap-filter ${isOpenFilter ? 'active' : ''}`}>
        <div
          className="overlay"
          onClick={() => {
            setIsOpenFilter(false);
          }}
        />
        <div className="content">
          <Form
            name="form-filter-case"
            onFinish={handleSearch}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className="header">
              <button onClick={() => setIsOpenFilter(false)} type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                >
                  <path
                    d="M10.4545 18.5713L1 9.9999L10.4545 1.42847"
                    stroke="#393F4D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <Form.Item name="keyword">
                <Input placeholder="ค้นหาเคส" prefix={<IconSearch />} />
              </Form.Item>
            </div>
            <div className="wrap-form">
              <Form.Item label="Start Date" name="createdAtFrom">
                <DatePicker size="large" allowClear format="DD/MM/YYYY" />
              </Form.Item>
              <Form.Item label="End Date" name="createdAtTo">
                <DatePicker size="large" allowClear format="DD/MM/YYYY" />
              </Form.Item>
              <div className="wrap-button">
                <Button primary type="submit">
                  ค้นหา
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Style>
  );
};

FollowUp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

FollowUp.defaultProps = {};

export default FollowUp;
