import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Icon from './Icon';

import useLoading from '../../../hooks/loading';
import { onClosePage } from '../../../utils';

import Style from './style';

const NoCase = () => {
  const loading = useLoading();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    onClosePage();
  };

  return (
    <Style>
      <Header title="ติดตามเคส" onBack={handleBack} />
      <div className="container">
        <Icon />
        <h1>ขออภัย</h1>
        <p>ท่านยังไม่ได้แจ้งเหตุเข้ามาในระบบ</p>
        <div className="wrap-button">
          <Button
            primary
            size="lg"
            onClick={handleBack}
          >
            กลับสู่หน้าหลัก
          </Button>
        </div>
      </div>
    </Style>
  );
};

NoCase.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

NoCase.defaultProps = {};

export default NoCase;
