import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/Button';

import useLoading from '../../hooks/loading';

import Icon from './Icon';
import Style from './style';

const PageNotFound = (props) => {
  const { history } = props;

  const loading = useLoading();
  React.useEffect(() => {
    loading.close();
    return () => {
      loading.open();
    };
  }, []); // eslint-disable-line

  return (
    <Style>
      <div className="container">
        <Icon />
        <h1>404</h1>
        <p className="message">ไม่พบหน้าที่ต้องการ</p>
        <Button primary size="lg" onClick={() => history.push('/')}>
          กลับสู่หน้าหลัก
        </Button>
      </div>
    </Style>
  );
};

PageNotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

PageNotFound.defaultProps = {};

export default PageNotFound;
