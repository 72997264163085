import styled from '@emotion/styled';

export default styled.div`
  label: component-input-phone;
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      &:focus {
        border-color: #f00;
      }
    }
    .ant-input-group-addon {
      width: 100px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #f2f6fb;
      border-right: none;
      padding: 0;
    }
    .ant-input {
      border-left: none;
      padding-left: 0;
    }
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent;
    box-shadow: unset;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: #d9d9d9;
    box-shadow: unset;
  }
`;
