import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Spin } from 'antd';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

import { getStatisticTotalUser } from '../../../service';
import Style from './style';

const CustomerChart = ({ year }) => {
  const [state, setState] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const res = await getStatisticTotalUser();

      setState(get(res, 'data'));
    } catch (err) {
      console.log('error', err);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [year]); // eslint-disable-line

  const COLORS = ['#FF7A44', '#FF937E', '#FFE0C2', '#FFD56A', '#A6D4FF', '#E3F1FF'];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = (values) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = values;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#393F4D"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };

  return (
    <Style>
      {loading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      <div className="wrap-hrader-chart">
        <div className="title">ประเภทผู้ใช้งาน</div>
      </div>
      <div className="wrap-chart" style={{ width: '100%', height: '200px' }}>
        {!loading && (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={200}>
              <Pie
                data={state}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                innerRadius={50}
                outerRadius={80}
                paddingAngle={2}
                fill="#8884d8"
                dataKey="totalUser"
              >
                {state.map((entry, index) => {
                  const key = `cell-${index}`;
                  return <Cell key={key} fill={COLORS[index] || '#EEEEEE'} />;
                })}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="legend">
        {!loading && (
          <ul>
            {state.map((obj, index) => {
              const key = `cell-${index}`;
              return (
                <li key={key}>
                  <div className="name">
                    <div
                      className="icon"
                      style={{ background: COLORS[index] || '#EEEEEE' }}
                    />
                    <span>{obj.name}</span>
                  </div>
                  <div className="value">
                    {Number(get(obj, 'totalUser'))
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Style>
  );
};

CustomerChart.propTypes = {
  year: PropTypes.string.isRequired
};

CustomerChart.defaultProps = {};

export default CustomerChart;
