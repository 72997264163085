import styled from '@emotion/styled';

export default styled.div`
  label: page-dashboard;
  font-family: Kanit;
  padding: 0 20px;
  max-width: 1440px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 50px;
  .container {
    max-width: 425px;
    margin: auto;
  }
  .wrap-country {
    margin-bottom: 16px;
  }
  .wrap-card {
    background: #ffffff;
    box-shadow: 0px 2px 10px #ffe0c2;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 16px;
    height: 100%;
  }
`;
