import React from 'react';
import PropTypes from 'prop-types';

import Style from './style';

const Header = (props) => {
  const { title, onBack } = props;
  return (
    <Style>
      <div className="wrap-header">
        {onBack !== undefined && (
          <button onClick={onBack} type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="20"
              viewBox="0 0 12 20"
              fill="none"
            >
              <path
                d="M10.4545 18.5713L1 9.9999L10.4545 1.42847"
                stroke="#393F4D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <div className="title">{title}</div>
      </div>
    </Style>
  );
};

Header.propTypes = {
  title: PropTypes.any.isRequired,
  onBack: PropTypes.func
};

Header.defaultProps = {
  onBack: undefined
};

export default Header;
