import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Radio } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Button from '../../components/Button';
import InputPhone from '../../components/InputPhone';
import UploadImageProfile from '../../components/UploadImageProfile';

import useLoading from '../../hooks/loading';
import { getRegisterRoles, updateUserProfile } from '../../service';
import { onClosePage, alert } from '../../utils';
import Style from './style';

const MyProfile = () => {
  const loading = useLoading();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const userProfile = useSelector(({ user }) => get(user, 'authUser', {}));
  const [roles, setRoles] = React.useState([]);

  const initPage = async () => {
    try {
      const res = await getRegisterRoles();
      setRoles(get(res, 'data', []));
    } catch (err) {
      console.err('initPage profile', err);
      alert.error(get(err, 'errorMessage', err));
    }
    loading.close();
  };

  React.useEffect(() => {
    initPage();
  }, []); // eslint-disable-line

  const handleBack = () => {
    onClosePage();
  };

  const onFinish = async (values) => {
    loading.open();
    try {
      const res = await updateUserProfile(values);
      dispatch({
        type: 'USER_LOGIN_SUCCESS',
        authUser: get(res, 'data', {}),
        accessToken: get(res, 'data.accessToken', '')
      });
      // history.push('/register/success');
      loading.close();
    } catch (err) {
      console.error('register >>>', err);
      alert.error(get(err, 'errorMessage', err));
      loading.close();
    }
  };

  if (isEmpty(userProfile)) return '';
  return (
    <Style>
      <Header title="ข้อมูลส่วนตัว" onBack={handleBack} />
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        initialValues={{
          email: get(userProfile, 'email', ''),
          gender: get(userProfile, 'gender', ''),
          firstName: get(userProfile, 'firstName', ''),
          lastName: get(userProfile, 'lastName', ''),
          telephone: get(userProfile, 'telephone', ''),
          lineId: get(userProfile, 'providerLoginId', ''),
          roleIds: get(userProfile, 'roleIds', ''),
          profileImageUrl: get(userProfile, 'profileImageUrl', '')
        }}
      >
        <section className="profile-image">
          <div className="wrap-image">
            <Form.Item name="profileImageUrl">
              <UploadImageProfile />
            </Form.Item>
          </div>
        </section>
        <div className="container">
          <h1>ข้อมูลส่วนตัว</h1>
          <Form.Item name="firstName" label="ชื่อ">
            <Input placeholder="ชื่อจริง" autoComplete="off" />
          </Form.Item>
          <Form.Item name="lastName" label="นามสกุล">
            <Input placeholder="นามสกุล" autoComplete="off" />
          </Form.Item>
          <Form.Item name="gender" label="เพศ">
            <Radio.Group>
              <Radio value="male">ชาย</Radio>
              <Radio value="female">หญิง</Radio>
              <Radio value="other">ไม่ระบุ</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="email" label="E-mail">
            <Input placeholder="abc@xyz.com" autoComplete="off" type="email" />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="เบอร์โทรศัพท์มือถือ (ที่ติดต่อได้)"
          >
            <InputPhone />
          </Form.Item>
          <Form.Item
            name="lineId"
            label="Line ID"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your line ID!'
            //   }
            // ]}
          >
            <Input disabled placeholder="Line ID" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="roleIds"
            label="กลุ่มบุคคล"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please input your Team!'
            //   }
            // ]}
          >
            <Select disabled placeholder="Influencer/Hope taskforce team">
              {map(roles, (role) => {
                return (
                  <Select.Option key={get(role, 'id')} value={get(role, 'id')}>
                    {get(role, 'name')}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button primary size="lg" type="submit" className="button-submit">
              บันทึก
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Style>
  );
};

MyProfile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired
};

MyProfile.defaultProps = {};

export default MyProfile;
