const base = process.env.REACT_APP_URL_API;

export const LOGIN = `${base}/users/login-line`;
export const LOGIN_EMAIL = `${base}/users/login`;
export const ACCESS_TOKEN = `${base}/users/access-tokens`;
export const UPLOADS = `${base}/uploads`;
export const CONSENTS_FORM = `${base}/consents/active-form`;
export const REGISTER_ROLES = `${base}/users/info/register-roles`;
export const REGISTER_USER = `${base}/users/register-line`;
export const USER = `${base}/users`;
export const INCIDENTS = `${base}/incidents`;
export const STATISTICS_PROVINCE = `${base}/statistics/by-province`;
export const STATISTICS_GENDER = `${base}/statistics/by-gender`;
export const STATISTICS_PROBLEM = `${base}/statistics/by-problem`;
export const STATISTICS_GENDER_MONTH = `${base}/statistics/by-gender-month`;
export const STATISTICS_GENDER_YEAR = `${base}/statistics/by-gender-year`;
export const STATISTICS_TOTAL_USER = `${base}/statistics/total-user`;
export const MESSAGES = `${base}/messages`;
