import styled from '@emotion/styled';

export default styled.div`
  label: page-unverified;
  background: #fff;
  font-family: Kanit;
  > .container {
    max-width: 370px;
    color: #393f4d;
    position: relative;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    h1 {
      margin-top: 40px;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px;
      flex: 1 1 100%;
      text-align: center;
    }
    p {
      margin-top: 0;
      margin-bottom: 70px;
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #393f4d;
      flex: 1 1 100%;
      text-align: center;
    }
    .wrap-button {
      max-width: 310px;
      flex: 1 1 100%;
      button {
        width: 100%;
      }
    }
  }
`;
