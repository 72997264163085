import React from 'react';
import PropTypes from 'prop-types';

import Style from './style';

const Button = (props) => {
  const { children, primary, className, disabled, onClick, type, size } = props;
  let styleButton = `${className} ${size}`;
  if (primary) styleButton = `${styleButton} primary`;
  return (
    <Style
      className={styleButton.trim()}
      disabled={disabled}
      type={type}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      {children}
    </Style>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'md', 'sm'])
};

Button.defaultProps = {
  children: '',
  primary: false,
  disabled: false,
  className: '',
  type: 'button',
  size: 'md',
  onClick: () => null
};

export default Button;
