import React from 'react';
import liff from '@line/liff';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Loading from './components/Loading';

import { login } from './service';
import { alert } from './utils';
import { appReady, loginSuccess } from './actions/action';

import Routers from './Routers';

const App = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(({ app }) => get(app, 'isReady'));
  const history = useHistory();

  const getUserProfile = async () => {
    try {
      const profile = await liff.getProfile();
      const email = liff.getDecodedIDToken().email;
      const lineIdToken = liff.getIDToken();
      // console.log('lineIdToken >>', lineIdToken);
      const userLogin = await login({
        lineIdToken,
      });
      /* ถ้า code 404 ให้ลงทะเบียนใหม่ */
      if (get(userLogin, 'code') === 404) {
        history.push('/register/condition');
      } else if (get(userLogin, 'code') === 401) {
        // token หมดอายุ
        await liff.logout();
        const href = window.location.href;
        liff.login({ redirectUri: href });
      } else if (get(userLogin, 'code') !== 200) {
        alert.error(get(userLogin, 'errorMessage', ''));
      } else {
        dispatch(loginSuccess(get(userLogin, 'data', {})));
        if (get(userLogin, 'data.status', '') === 'wait_verify') {
          history.push('/register/unverified');
        }
      }
      dispatch(
        appReady({
          ...profile,
          email,
        })
      );
    } catch (err) {
      console.log('getUserProfile', err);
      alert.error(get(err, 'errorMessage', err));
    }
  };

  const initLiff = async () => {
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
      // await liff.init({ liffId: '1656406664-n1Wm8m9N' });
      if (liff.isLoggedIn()) {
        getUserProfile();
      } else {
        const href = window.location.href;
        liff.login({ redirectUri: href });
      }
    } catch (err) {
      console.error('initLiff', err);
      alert.error(get(err, 'errorMessage', err));
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      initLiff();
    }, 1000);
  }, []); // eslint-disable-line

  return (
    <>
      <ToastContainer />
      <Loading />
      {isReady && (
        <div className="wrap-page">
          <Routers />
        </div>
      )}
    </>
  );
};

export default App;
