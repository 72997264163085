import styled from '@emotion/styled';

export default styled.div`
  label: page-follow-up;
  background: rgba(255, 209, 154, 0.1);
  font-family: Kanit;
  min-height: 100vh;
  padding-bottom: 50px;
  .wrap-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      flex: 1 1 auto;
    }
    > button {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      outline: none;
    }
  }
  .container {
    max-width: 400px;
    color: #393f4d;
    position: relative;
    margin: auto;
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
    }
    ul.wrap-case {
      margin: 24px 0;
      padding: 0;

      > li {
        margin: 0;
        margin-bottom: 8px;
        background: #ffffff;

        box-shadow: 0px 2px 10px #ffe0c2;
        border-radius: 20px;
      }
    }
    ul.wrap-case > li > a {
      font-family: Kanit;
      display: flex;
      align-items: flex-start;
      padding: 20px;
      > .wrap-image {
        flex: 0 0 48px;
        height: 48px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 16px;
      }
      .info {
        .title {
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 28px;
          color: #393f4d;
        }
        p {
          font-weight: 300;
          font-size: 12px;
          margin-bottom: 4px;
          color: rgba(57, 63, 77, 0.5);
        }
        .status {
          font-weight: 300;
          font-size: 12px;
          padding: 4px 20px;
          border-radius: 30px;
          margin-top: 6px;
          display: inline-block;
          &.case-10 {
            color: #eb0303;
            background: #ffdede;
          }
          &.case-20 {
            color: #fba500;
            background: #fff2ce;
          }
          &.case-30 {
            color: #ff7a44;
            background: #feecd7;
          }
          &.case-40 {
            color: #7b61ff;
            background: #e4e5ff;
          }
          &.case-50 {
            color: #1172bc;
            background: #e3f1ff;
          }
          &.case-60,
          &.case-deleted {
            color: #393f4d;
            background: #d4d4dc;
          }
        }
      }
    }
  }
  .wrap-filter {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    > .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
    > .content {
      position: relative;
      z-index: 2;
      max-width: 425px;
      width: 100%;
      height: 100%;
      background: #fffaf5;
      box-shadow: -6px 1px 20px #ddd;
      @media (max-width: 425px) {
        box-shadow: unset;
      }
      .header {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 68px;
        padding: 0 20px;
        button {
          background: none;
          outline: none;
          border: none;
          margin-right: 10px;
        }
        .ant-row {
          margin: 0;
          flex: 1 1 auto;
          .ant-input-affix-wrapper {
            border-radius: 30px;
            background: #f2f6fb;
          }
          .ant-input {
            background: none;
            border-radius: 0px;
            height: 30px;
          }
        }
      }
      .wrap-form {
        padding: 20px;
        .ant-picker {
          width: 100%;
          border-radius: 10px;
        }
      }
      .wrap-button {
        display: flex;
        justify-content: center;
        button {
          width: 250px;
        }
      }
    }
  }
`;
