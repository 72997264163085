import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Collapse, Radio, Modal } from 'antd';
import get from 'lodash/get';

import Header from '../../components/Header';
import Button from '../../components/Button';
import UploadFiles from '../../components/UploadFiles';
import InputPhone from '../../components/InputPhone';
import GoogleMap from '../../components/GoogleMap';
import useLoading from '../../hooks/loading';

import { createIncidents } from '../../service';
import { alert, onClosePage } from '../../utils';
import Style from './style';
import Icon from './Icons';

const PageReport = (props) => {
  const { history } = props;
  const loading = useLoading();
  const [form] = Form.useForm();

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    onClosePage();
  };

  const handleSubmit = async () => {
    try {
      // loading.open();
      const values = form.getFieldsValue();

      const body = {
        files: get(values, 'files', []),
        detail: get(values, 'detail', ''),
        gender: get(values, 'gender', ''),
        firstName: get(values, 'firstName', ''),
        lastName: get(values, 'lastName', ''),
        telephone: get(values, 'telephone', ''),
        address: get(values, 'address', ''),
        latitude: get(values, 'map.lat', ''),
        longitude: get(values, 'map.lng', ''),
        contactFirstName: get(values, 'contactFirstName', ''),
        contactLastName: get(values, 'contactLastName', ''),
        contactTelephone: get(values, 'contactTelephone', ''),
        contactGender: get(values, 'contactGender', ''),
        contactRelation: get(values, 'contactRelation', ''),
        contactAddress: get(values, 'contactAddress', ''),
        contactLatitude: get(values, 'contactMap.lat', ''),
        contactLongitude: get(values, 'contactMap.lng', '')
      };
      await createIncidents(body);
      history.push('/reports/success');
    } catch (err) {
      console.log('err >>>', err);
      alert.error(get(err, 'errorMessage', err));
    }
  };

  const onFinish = () => {
    Modal.confirm({
      title: '',
      centered: true,
      content: <div className="content-confirm">ยืนยันการส่งเคส</div>,
      okText: 'ยืนยัน',
      cancelText: 'กลับ',
      onOk: handleSubmit
    });
  };

  return (
    <Style>
      <Header title="แจ้งเหตุ" onBack={handleBack} />
      <div className="header">
        <div>
          <h1>แจ้งรายละเอียดผู้มีความเสี่ยง</h1>
          <p>
            กรุณาแจ้งรายละเอียดให้ได้มากที่สุด เพื่อความสะดวก
            รวดเร็วแก่เจ้าหน้าที่
          </p>
        </div>
        <Icon />
      </div>
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <div className="container">
          <div className="section-form">
            <div className="title">แจ้งรายละเอียดเหตุการณ์</div>
            <Form.Item name="files" label="แนบรูปภาพหรือคลิปวิดีโอ">
              <UploadFiles />
            </Form.Item>
            <Form.Item
              name="detail"
              label="รายละเอียดความเสี่ยง"
              rules={[
                { required: true, message: 'กรุณาระบุรายละเอียดความเสี่ยง' }
              ]}
            >
              <Input.TextArea
                rows={5}
                placeholder="รายละเอียดความเสี่ยง"
                autoComplete="off"
              />
            </Form.Item>
          </div>
          <div className="section-form">
            <div className="title">ข้อมูลเพิ่มเติม (ถ้ามี)</div>
            <p>
              หากมีรายละเอียดจำเพาะ ประกอบด้วย ชื่อ-สกุล, เบอร์ติดต่อ
              หรือสถานที่ สามารถเพิ่มได้
            </p>
            <Collapse>
              <Collapse.Panel header="เพิ่มข้อมูลผู้มีความเสี่ยง" key="1">
                <div className="content-panel">
                  <Form.Item name="firstName" label="ชื่อ">
                    <Input placeholder="ชื่อ" autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="lastName" label="นามสกุล ">
                    <Input placeholder="นามสกุล " autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="gender" label="เพศ">
                    <Radio.Group>
                      <Radio value="male">ชาย</Radio>
                      <Radio value="female">หญิง</Radio>
                      <Radio value="other">ไม่ระบุ</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="telephone" label="เบอร์โทรศัพท์มือถือ">
                    <InputPhone />
                  </Form.Item>
                  <Form.Item name="address" label="รายละเอียดที่อยู่">
                    <Input.TextArea
                      rowa={4}
                      placeholder="รายละเอียดที่อยู่"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="map">
                    <GoogleMap />
                  </Form.Item>
                </div>
              </Collapse.Panel>
            </Collapse>
            <Collapse>
              <Collapse.Panel header="เพิ่มข้อมูลผู้เกี่ยวข้อง" key="1">
                <div className="content-panel">
                  <Form.Item name="contactFirstName" label="ชื่อ">
                    <Input placeholder="ชื่อ" autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="contactLastName" label="นามสกุล ">
                    <Input placeholder="นามสกุล " autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="contactGender" label="เพศ">
                    <Radio.Group>
                      <Radio value="male">ชาย</Radio>
                      <Radio value="female">หญิง</Radio>
                      <Radio value="other">ไม่ระบุ</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="contactTelephone"
                    label="เบอร์โทรศัพท์มือถือ"
                  >
                    <InputPhone />
                  </Form.Item>
                  <Form.Item name="contactRelation" label="ความเกี่ยวข้อง">
                    <Input placeholder="ความเกี่ยวข้อง" autoComplete="off" />
                  </Form.Item>
                  <Form.Item name="contactAddress" label="รายละเอียดที่อยู่">
                    <Input.TextArea
                      rowa={4}
                      placeholder="รายละเอียดที่อยู่"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item name="contactMap">
                    <GoogleMap />
                  </Form.Item>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
          <Form.Item>
            <Button primary size="lg" type="submit" className="button-submit">
              เสร็จสิ้น
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Style>
  );
};

PageReport.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired
};

PageReport.defaultProps = {};

export default PageReport;
