import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Autocomplete from './Autocomplete';
import Button from '../Button';

import { StyleGoogleMap } from './style';

const Marker = () => (
  <div className="marker">
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1059 2.82156C14.3545 1.01428 11.9794 -0.000994682 9.50285 -0.000994682C7.02634 -0.000994682 4.65123 1.01428 2.89983 2.82156L2.85568 2.86711C1.94965 3.80058 1.23087 4.9093 0.74044 6.12982C0.25001 7.35035 -0.00244141 8.65872 -0.00244141 9.98005C-0.00244141 11.3014 0.25001 12.6098 0.74044 13.8303C1.23087 15.0508 1.94965 16.1595 2.85568 17.093L9.54209 23.9997L16.1059 17.2322C17.0247 16.2872 17.7538 15.1643 18.2513 13.9278C18.7487 12.6914 19.0048 11.3657 19.0048 10.0269C19.0048 8.68804 18.7487 7.36238 18.2513 6.12592C17.7538 4.88947 17.0247 3.76654 16.1059 2.82156ZM13.8722 14.2104C13.0078 15.1015 11.9068 15.7082 10.7084 15.9536C9.51003 16.1991 8.26806 16.0723 7.13946 15.5894C6.01086 15.1064 5.0463 14.289 4.36769 13.2403C3.68908 12.1917 3.32691 10.9589 3.32691 9.69787C3.32691 8.4368 3.68908 7.20405 4.36769 6.1554C5.0463 5.10676 6.01086 4.28931 7.13946 3.80636C8.26806 3.32342 9.51003 3.19666 10.7084 3.44211C11.9068 3.68757 13.0078 4.29421 13.8722 5.18537C14.4465 5.77623 14.9022 6.47825 15.2132 7.25119C15.5242 8.02412 15.6844 8.8528 15.6846 9.68975C15.6848 10.5267 15.5251 11.3555 15.2145 12.1286C14.9039 12.9017 14.4486 13.604 13.8747 14.1952L13.8722 14.2104Z"
        fill="#FF7A44"
      />
    </svg>
  </div>
);

const GoogleMap = ({
  onChange,
  mapStyle,
  defaultCenter,
  value,
  defaultZoom,
  disabled
}) => {
  const [mark, setMark] = React.useState({});
  const [center, setCenter] = React.useState(defaultCenter);
  // const [state, setState] = React.useState({
  //   isReady: false,
  //   map: null,
  //   maps: null
  // });

  React.useEffect(() => {
    if (!isEmpty(value)) {
      setMark(value);
      setCenter(value);
    }
    // eslint-disable-next-line
  }, [get(value, 'lat', 0), get(value, 'lng', 0)]);

  // const handleApiLoaded = (map, maps) => {
  //   setState({
  //     isReady: true,
  //     map,
  //     maps
  //   });
  // };

  const handleSelectMark = (data) => {
    setMark(data);
  };

  // const getLocation = () => {
  //   try {
  //     const options = {
  //       enableHighAccuracy: true,
  //       timeout: 5000,
  //       maximumAge: 0
  //     };
  //     const success = (pos) => {
  //       const crd = pos.coords;
  //       console.log('Your current position is:');
  //       console.log(`Latitude : ${crd.latitude}`);
  //       console.log(`Longitude: ${crd.longitude}`);
  //       console.log(`More or less ${crd.accuracy} meters.`);
  //     };
  //     const error = (err) => {
  //       console.warn(`ERROR(${err.code}): ${err.message}`);
  //     };
  //     navigator.geolocation.getCurrentPosition(success, error, options);
  //   } catch (err) {
  //     console.log('err >>>', err);
  //   }
  // };

  const handleSelected = () => {
    if (onChange) onChange(mark);
  };

  const handleFilter = (data) => {
    setMark(data);
    setCenter(data);
  };

  // const mapsSelector = () => {
  //   try {
  //     const link = `//maps.google.com/maps?daddr=${get(mark, 'lat', 0)},${get(
  //       mark,
  //       'lng',
  //       0
  //     )}&amp;ll=`;
  //     if (
  //       navigator.platform.indexOf('iPhone') !== -1
  //       || navigator.platform.indexOf('iPad') !== -1
  //       || navigator.platform.indexOf('iPod') !== -1
  //     ) {
  //       window.open(`maps:${link}`);
  //     } else {
  //       window.open(`https:${link}`);
  //     }
  //   } catch (err) {
  //     console.log('mapsSelector err', err);
  //   }
  // };

  return (
    <StyleGoogleMap className={disabled ? 'disabled' : ''}>
      <GoogleMapReact
        className="google-map"
        bootstrapURLKeys={{ key: 'AIzaSyAnWM5W-T7a9fDedf2EWZQLhk91ZdX0nEI' }}
        center={center}
        style={mapStyle}
        defaultZoom={defaultZoom}
        // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        }}
        onClick={handleSelectMark}
      >
        {!isEmpty(mark) && (
          <Marker
            lat={get(mark, 'lat', 0)}
            lng={get(mark, 'lng', 0)}
            title="Mark"
          />
        )}
      </GoogleMapReact>
      {!disabled && (
        <div className="wrap-autocomplete">
          <Autocomplete onSelect={handleFilter} />
        </div>
      )}
      {onChange !== undefined && (
        <div className="group-button">
          <Button
            primary
            size="lg"
            disabled={isEmpty(mark)}
            onClick={handleSelected}
          >
            ยืนยันตำแหน่ง
          </Button>
        </div>
      )}
    </StyleGoogleMap>
  );
};

GoogleMap.propTypes = {
  onChange: PropTypes.func,
  mapStyle: PropTypes.shape(),
  value: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  defaultZoom: PropTypes.number,
  disabled: PropTypes.bool
};

GoogleMap.defaultProps = {
  onChange: undefined,
  value: undefined,
  disabled: false,
  defaultZoom: 10,
  mapStyle: { height: '400px', width: '100%', maxWidth: '100vw' },
  defaultCenter: {
    lat: 13.735284354844094,
    lng: 100.51794485555227
  }
};

export default GoogleMap;
