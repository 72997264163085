const innitialState = {
  isLoading: true,
  isReady: false,
  userProfile: {}
};

export default (state = innitialState, action) => {
  switch (action.type) {
    case 'INITAPP': {
      return {
        ...state,
        isReady: true,
        userProfile: action.profile
      };
    }
    case 'CONDITION_ACCEPT': {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          consentFormId: action.id,
          consentedKeys: action.values,
        }
      };
    }
    case 'APP_LOADING_OPEN': {
      return {
        ...state,
        isLoading: true
      };
    }
    case 'APP_LOADING_CLOSE': {
      return {
        ...state,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
