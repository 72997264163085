import { fetchWithResponse, GET, POST, PATCH } from './api';
import {
  LOGIN,
  LOGIN_EMAIL,
  UPLOADS,
  CONSENTS_FORM,
  REGISTER_ROLES,
  REGISTER_USER,
  INCIDENTS,
  USER,
  STATISTICS_PROVINCE,
  STATISTICS_GENDER,
  STATISTICS_PROBLEM,
  STATISTICS_GENDER_MONTH,
  STATISTICS_GENDER_YEAR,
  STATISTICS_TOTAL_USER,
  ACCESS_TOKEN,
  MESSAGES
} from './apiList';

export const login = (data) => {
  return new Promise((resolve) => {
    fetchWithResponse(LOGIN, { method: 'POST', data })
      .then((body) => {
        resolve(body);
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const loginEmail = (body) => POST(LOGIN_EMAIL, body, 0);

export const getAccessToken = () => {
  return GET(ACCESS_TOKEN, {}, 0);
};

export const uploadFile = (files) => {
  const formData = new FormData();
  formData.append('files', files);
  return fetchWithResponse(
    UPLOADS,
    {
      method: 'POST',
      data: formData
    },
    0
  );
};

export const getConsentForm = () => GET(CONSENTS_FORM, {}, 0);
export const getRegisterRoles = () => GET(REGISTER_ROLES, {}, 0);
export const registerUser = (body) => POST(REGISTER_USER, body, 0);
export const updateUserProfile = (body) => PATCH(`${USER}/me`, body, 0);
export const createIncidents = (body) => POST(INCIDENTS, body, 0);
export const getMyIncidents = (query = {}) => GET(`${INCIDENTS}/me`, query, 0);
export const getIncidents = (query = {}) => GET(`${INCIDENTS}/active`, query, 0);
export const getIncidentById = (id) => GET(`${INCIDENTS}/${id}`, {}, 0);
export const createIncidentNote = (id, body) => POST(`${INCIDENTS}/${id}/notes`, body, 0);

export const getStatisticProvince = (query) => GET(STATISTICS_PROVINCE, query, 1000);
export const getStatisticGender = (query) => GET(STATISTICS_GENDER, query, 1000);
export const getStatisticProblem = (query) => GET(STATISTICS_PROBLEM, query, 1000);
export const getStatisticGenderMonth = (query) => GET(STATISTICS_GENDER_MONTH, query, 1000);
export const getStatisticGenderYear = () => GET(STATISTICS_GENDER_YEAR, {}, 1000);
export const getStatisticTotalUser = () => GET(STATISTICS_TOTAL_USER, {}, 1000);
export const getMessages = (userId, query = {}) => GET(`${MESSAGES}/${userId}`, query, 1000);
export const getMessageChannel = (userId) => GET(`${MESSAGES}/channel/${userId}`, {}, 0);
export const createMessage = (body) => POST(`${MESSAGES}/create/`, body, 0);
