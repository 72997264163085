import React from 'react';

export default () => (
  <svg
    width="258"
    height="224"
    viewBox="0 0 258 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M118.026 223.968C179.787 223.968 229.854 173.831 229.854 111.984C229.854 50.137 179.787 0 118.026 0C56.2648 0 6.19763 50.137 6.19763 111.984C6.19763 173.831 56.2648 223.968 118.026 223.968Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M189.418 156.522H25.2465V114.064H99.2807V116.185H27.3654V154.4H187.3V43.5827H94.7816V2.20667H96.8934V41.4609H189.418V156.522Z"
      fill="white"
    />
    <path
      d="M154.28 56.0524H81.8139C77.626 56.0524 73.6094 57.7174 70.6467 60.6815C67.6841 63.6456 66.0178 67.6664 66.014 71.8602V211.145C82.0612 219.58 99.9133 223.986 118.036 223.986C136.16 223.986 154.012 219.58 170.059 211.145V71.9026C170.064 69.8237 169.66 67.7641 168.87 65.8418C168.08 63.9195 166.918 62.1722 165.453 60.6999C163.987 59.2276 162.246 58.0592 160.329 57.2617C158.412 56.4643 156.356 56.0533 154.28 56.0524Z"
      fill="#1A2E35"
    />
    <path
      d="M154.443 62.9481H141.68C141.118 62.9481 140.579 63.1717 140.181 63.5696C139.784 63.9675 139.561 64.5072 139.561 65.07V66.527C139.559 67.5675 139.145 68.5649 138.411 69.3006C137.676 70.0364 136.68 70.4505 135.641 70.4524H100.425C99.3852 70.4524 98.3882 70.0388 97.653 69.3027C96.9179 68.5665 96.5049 67.5681 96.5049 66.527V65.07C96.5049 64.5072 96.2816 63.9675 95.8843 63.5696C95.4869 63.1717 94.948 62.9481 94.386 62.9481H81.5949C80.1731 62.9454 78.7647 63.2234 77.4503 63.7663C76.1359 64.3092 74.9413 65.1064 73.935 66.1122C72.9286 67.118 72.1302 68.3127 71.5855 69.6279C71.0407 70.943 70.7603 72.3528 70.7603 73.7767V213.508C85.5573 220.398 101.679 223.968 117.998 223.968C134.316 223.968 150.438 220.398 165.235 213.508V73.7767C165.237 70.9079 164.101 68.1557 162.077 66.1252C160.053 64.0946 157.307 62.9519 154.443 62.9481Z"
      fill="white"
    />
    <path
      d="M193.734 25.6886C193.024 24.9769 192.18 24.4123 191.252 24.0271C190.324 23.6419 189.329 23.4436 188.324 23.4436C187.319 23.4436 186.324 23.6419 185.395 24.0271C184.467 24.4123 183.624 24.9769 182.913 25.6886L182.878 25.7239C182.135 26.4663 181.545 27.3483 181.143 28.3192C180.741 29.2901 180.534 30.331 180.534 31.3822C180.534 32.4334 180.741 33.4743 181.143 34.4452C181.545 35.4161 182.135 36.2981 182.878 37.0405L188.359 42.529L193.734 37.1395C194.487 36.3889 195.085 35.4968 195.493 34.5142C195.9 33.5316 196.11 32.4781 196.11 31.414C196.11 30.35 195.9 29.2964 195.493 28.3139C195.085 27.3313 194.487 26.4391 193.734 25.6886ZM191.905 34.7347C191.196 35.4439 190.294 35.9269 189.312 36.1226C188.329 36.3182 187.311 36.2178 186.386 35.834C185.46 35.4502 184.669 34.8002 184.113 33.9662C183.557 33.1322 183.26 32.1518 183.26 31.1488C183.26 30.1458 183.557 29.1653 184.113 28.3314C184.669 27.4974 185.46 26.8474 186.386 26.4636C187.311 26.0798 188.329 25.9793 189.312 26.175C190.294 26.3707 191.196 26.8537 191.905 27.5629C192.377 28.0323 192.753 28.5909 193.009 29.2064C193.265 29.8219 193.397 30.482 193.397 31.1488C193.397 31.8156 193.265 32.4757 193.009 33.0912C192.753 33.7067 192.377 34.2652 191.905 34.7347Z"
      fill="#FF7A44"
    />
    <path
      d="M52.9616 136.046C52.2513 135.334 51.4078 134.77 50.4795 134.385C49.5512 133.999 48.5562 133.801 47.5514 133.801C46.5465 133.801 45.5515 133.999 44.6232 134.385C43.6949 134.77 42.8515 135.334 42.1411 136.046C41.3979 136.789 40.8083 137.67 40.406 138.641C40.0037 139.612 39.7966 140.653 39.7966 141.704C39.7966 142.756 40.0037 143.796 40.406 144.767C40.8083 145.738 41.3979 146.62 42.1411 147.363L47.622 152.851L52.9969 147.462C54.5027 145.943 55.3447 143.887 55.3381 141.747C55.3315 139.606 54.4767 137.556 52.9616 136.046ZM51.1323 145.092C50.4241 145.801 49.5217 146.284 48.5394 146.48C47.557 146.676 46.5388 146.575 45.6134 146.192C44.6881 145.808 43.8972 145.158 43.3407 144.324C42.7843 143.49 42.4873 142.509 42.4873 141.506C42.4873 140.503 42.7843 139.523 43.3407 138.689C43.8972 137.855 44.6881 137.205 45.6134 136.821C46.5388 136.437 47.557 136.337 48.5394 136.533C49.5217 136.728 50.4241 137.211 51.1323 137.92C51.6034 138.391 51.9772 138.95 52.2322 139.565C52.4872 140.18 52.6185 140.84 52.6185 141.506C52.6185 142.173 52.4872 142.832 52.2322 143.448C51.9772 144.063 51.6034 144.622 51.1323 145.092Z"
      fill="#FF7A44"
    />
    <path
      d="M97.0771 193.06C97.8611 197.94 96.3284 202.057 95.1771 206.859L91.9493 220.899C99.8817 223.164 108.114 224.199 116.359 223.969L118.711 202.495C119.029 195.019 124.849 190.429 128.423 183.858L130.541 179.919L142.725 147.476C141.419 147.24 140.074 147.385 138.848 147.893C137.739 148.357 136.679 148.93 135.683 149.605C133.508 151.148 131.798 153.259 130.739 155.709C128.832 159.952 124.602 165.717 124.58 165.745L123.062 166.056L117.644 150.828C117.529 150.225 117.279 149.656 116.913 149.164C116.548 148.671 116.076 148.267 115.533 147.981C114.99 147.696 114.39 147.536 113.778 147.515C113.165 147.494 112.555 147.611 111.994 147.858L97.4514 151.288C96.7093 151.462 96.0419 151.869 95.5465 152.449C95.0511 153.029 94.7534 153.752 94.6968 154.513L93.9199 164.896C93.6303 167.812 93.6303 170.75 93.9199 173.666L97.0771 193.06Z"
      fill="#FFBEB8"
    />
    <path
      d="M126.593 167.522C126.593 167.522 126.61 167.497 126.593 167.522V167.522Z"
      fill="#FFBF9D"
    />
    <path
      d="M103.328 182.656C101.413 181.484 100.04 179.6 99.5103 177.416C98.9807 175.233 99.3375 172.928 100.503 171.007L123.945 133.995C124.025 133.865 124.153 133.772 124.301 133.737C124.449 133.701 124.605 133.725 124.736 133.804L138.198 142.051C138.328 142.132 138.42 142.262 138.456 142.411C138.492 142.561 138.467 142.718 138.388 142.85L114.968 179.827C113.798 181.746 111.915 183.123 109.732 183.653C107.55 184.183 105.246 183.825 103.328 182.656Z"
      fill="#1A2E35"
    />
    <path
      d="M132.016 121.355C136.69 113.708 138.537 106.32 136.141 104.851C133.746 103.383 128.015 108.391 123.341 116.038C118.668 123.684 116.821 131.072 119.217 132.541C121.612 134.009 127.343 129.001 132.016 121.355Z"
      fill="#A93C1E"
    />
    <path
      d="M63.2347 76.3524C76.5935 54.4966 82.0073 33.4595 75.3266 29.3647C68.6459 25.2699 52.4007 39.668 39.0418 61.5237C25.683 83.3795 20.2693 104.417 26.9499 108.511C33.6306 112.606 49.8758 98.2081 63.2347 76.3524Z"
      fill="#FF7A44"
    />
    <path
      d="M77.4349 32.0541C80.3095 39.4098 74.6944 57.5729 63.2312 76.3371C51.4784 95.5611 37.4937 109.014 29.7527 109.183L128.634 133.563L141.037 113.342L77.4349 32.0541Z"
      fill="#FFE0C2"
    />
    <path
      d="M67.5537 61.654C66.7682 60.9509 65.9221 60.319 65.0252 59.7656C61.076 57.4405 56.373 56.7615 51.9289 57.8747C47.4847 58.988 43.6548 61.8043 41.2639 65.7175C38.873 69.6306 38.1123 74.3275 39.1456 78.7967C40.1789 83.266 42.9235 87.1502 46.7885 89.6129C47.6882 90.1546 48.6335 90.616 49.6137 90.9921C53.2996 86.522 56.663 81.7949 59.6785 76.8465C62.6577 71.9744 65.2893 66.8976 67.5537 61.654Z"
      fill="white"
    />
    <path
      d="M152.769 115.018L136.135 104.855C138.53 106.319 136.686 113.71 132.011 121.356C127.335 129.001 121.614 133.988 119.212 132.517L135.846 142.716C139.516 144.954 143.922 145.645 148.1 144.638C152.277 143.631 155.886 141.006 158.136 137.34C160.372 133.665 161.062 129.253 160.056 125.069C159.05 120.886 156.43 117.272 152.769 115.018Z"
      fill="#FF7A44"
    />
    <path
      d="M145.918 145.021L124.093 130.876L123.507 131.321L144.752 145.071C145.141 145.078 145.529 145.057 145.918 145.021Z"
      fill="#1A2E35"
    />
    <path
      d="M152.691 142.758L128.098 126.844L127.646 127.375L152.013 143.147L152.691 142.758Z"
      fill="#1A2E35"
    />
    <path
      d="M157.275 138.614C157.409 138.43 157.55 138.239 157.677 138.048L132.067 121.243L132.011 121.328C131.912 121.497 131.806 121.667 131.7 121.83L157.275 138.614Z"
      fill="#1A2E35"
    />
    <path
      d="M160.298 131.562L135.288 115.125L135.111 115.4L134.963 115.74L160.157 132.297C160.206 132.057 160.255 131.809 160.298 131.562Z"
      fill="#1A2E35"
    />
    <path
      d="M159.888 124.39L137.032 109.756L136.905 109.961C136.905 110.117 136.842 110.272 136.799 110.435L160.107 125.366C160.065 125.041 159.98 124.708 159.888 124.39Z"
      fill="#1A2E35"
    />
    <path
      d="M111.358 141.634L124.884 155.864C125.05 156.037 125.177 156.244 125.255 156.471C125.334 156.698 125.363 156.939 125.34 157.178C125.318 157.417 125.243 157.648 125.123 157.856C125.003 158.064 124.84 158.243 124.644 158.382C123.832 158.962 122.882 159.316 121.89 159.409C120.898 159.502 119.898 159.331 118.993 158.912C114.452 157.074 104.239 145.679 104.239 145.679C104.239 145.679 104.204 141.485 107.297 140.509C108.011 140.297 108.771 140.291 109.488 140.49C110.206 140.688 110.854 141.085 111.358 141.634Z"
      fill="#FFBEB8"
    />
    <path
      d="M115.561 140.318L132.865 147.108C133.026 147.173 133.171 147.27 133.293 147.394C133.414 147.518 133.508 147.665 133.57 147.827C133.632 147.989 133.659 148.162 133.651 148.335C133.643 148.509 133.599 148.678 133.522 148.834C133.128 149.641 132.516 150.322 131.756 150.799C130.996 151.277 130.118 151.532 129.221 151.536C125.053 151.769 111.796 145.835 111.796 145.835C111.796 145.835 110.384 142.553 112.856 140.65C113.24 140.367 113.691 140.188 114.165 140.13C114.639 140.072 115.119 140.136 115.561 140.318Z"
      fill="#FFBEB8"
    />
    <path
      d="M104.747 145.474L120.66 162.216C120.803 162.364 120.912 162.541 120.982 162.734C121.052 162.928 121.081 163.134 121.066 163.339C121.051 163.545 120.994 163.745 120.897 163.926C120.801 164.108 120.667 164.267 120.505 164.394C119.691 165.05 118.709 165.464 117.671 165.589C116.634 165.714 115.582 165.545 114.636 165.101C110.094 163.269 99.8811 151.875 99.8811 151.875C99.8811 151.875 98.6733 144.102 104.747 145.474Z"
      fill="#FFBEB8"
    />
    <path
      d="M100.827 151.79L113.046 167.35C113.163 167.49 113.222 167.669 113.21 167.851C113.198 168.033 113.117 168.203 112.983 168.326C112.065 169.21 109.797 169.854 107.029 168.404C103.384 166.509 95.8339 157.286 95.8339 157.286C95.8339 157.286 95.8692 150.121 100.827 151.79Z"
      fill="#FFBEB8"
    />
    <path
      d="M111.365 141.619L118.577 149.209"
      stroke="#1A2E35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.754 145.474L116.232 157.547"
      stroke="#1A2E35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.881 151.875L107.594 160.405"
      stroke="#1A2E35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.881 161.621L104.26 166.339"
      stroke="#1A2E35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M189.418 198.287H187.299V154.4H220.199V156.522H189.418V198.287Z"
      fill="white"
    />
    <path
      d="M218.813 151C239.8 151 256.813 133.539 256.813 112C256.813 90.4609 239.8 73 218.813 73C197.826 73 180.813 90.4609 180.813 112C180.813 133.539 197.826 151 218.813 151Z"
      fill="#FFE0C2"
    />
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="180"
      y="73"
      width="78"
      height="78"
    >
      <path
        d="M218.926 150.212C240.084 150.212 257.236 133.037 257.236 111.849C257.236 90.6619 240.084 73.4862 218.926 73.4862C197.768 73.4862 180.617 90.6619 180.617 111.849C180.617 133.037 197.768 150.212 218.926 150.212Z"
        fill="#FFE0C2"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M213.68 85.2142C213.68 85.2142 218.32 78.5304 226.542 82.9863C235.554 87.8807 230.561 100.046 230.561 100.046L211.321 94.515L213.68 85.2142Z"
        fill="#1A2E35"
      />
      <path
        d="M209.944 100.046C209.944 100.046 204.717 88.0221 213.525 85.3981L215.114 90.9997L209.944 100.046Z"
        fill="#1A2E35"
      />
      <path
        d="M219.33 115.38L206.963 117.777C203.389 118.485 200.289 121.936 201.051 125.501L201.419 151.777H238.323L237.073 125.607C237.934 122.021 234.445 118.407 230.836 117.693L219.33 115.38Z"
        fill="white"
      />
      <path
        d="M206.942 117.777L219.33 115.38L230.836 117.678L230.575 117.855C227.119 120.301 222.988 121.607 218.757 121.593C214.525 121.579 210.403 120.246 206.963 117.777H206.942Z"
        fill="white"
      />
      <path
        d="M211.561 99.2751C211.561 99.2751 207.232 95.6185 206.78 100.089C206.328 104.559 210.883 105.513 211.943 103.264L211.561 99.2751Z"
        fill="#FFBCB6"
      />
      <path
        d="M211.053 101.906C211.012 101.903 210.975 101.886 210.947 101.857C210.728 101.602 210.057 100.93 209.577 100.895C209.521 100.89 209.466 100.897 209.413 100.916C209.361 100.934 209.313 100.963 209.273 101.001C209.246 101.027 209.211 101.041 209.174 101.041C209.137 101.041 209.101 101.027 209.075 101.001C209.061 100.988 209.05 100.973 209.043 100.956C209.035 100.939 209.032 100.92 209.032 100.902C209.032 100.883 209.035 100.865 209.043 100.848C209.05 100.831 209.061 100.815 209.075 100.803C209.215 100.679 209.397 100.615 209.584 100.626C209.98 100.697 210.346 100.884 210.636 101.164C210.445 100.729 210.169 100.336 209.824 100.011C209.386 99.6075 209.019 99.4519 208.722 99.558C208.122 99.7702 207.938 100.973 207.938 100.973C207.938 100.993 207.934 101.013 207.926 101.032C207.917 101.051 207.905 101.068 207.889 101.081C207.874 101.095 207.856 101.104 207.836 101.11C207.816 101.116 207.795 101.117 207.775 101.114C207.755 101.111 207.736 101.103 207.719 101.092C207.701 101.081 207.687 101.066 207.676 101.048C207.666 101.031 207.659 101.011 207.656 100.991C207.654 100.97 207.656 100.95 207.662 100.93C207.662 100.881 207.867 99.5934 208.63 99.3246C209.033 99.1832 209.499 99.3246 210.043 99.8339C210.432 100.204 210.74 100.652 210.947 101.149L211.201 101.744C211.215 101.774 211.218 101.809 211.209 101.841C211.2 101.873 211.18 101.902 211.152 101.92C211.118 101.926 211.083 101.921 211.053 101.906Z"
        fill="#1A2E35"
      />
      <path
        d="M228.654 99.7065C228.654 99.7065 233.252 95.8305 233.725 100.569C234.198 105.308 229.374 106.32 228.258 103.936L228.654 99.7065Z"
        fill="#FFBCB6"
      />
      <path
        d="M229.219 102.472C229.193 102.478 229.167 102.478 229.141 102.472C229.113 102.453 229.093 102.425 229.084 102.393C229.075 102.36 229.077 102.326 229.092 102.295L229.36 101.666C229.577 101.131 229.904 100.649 230.321 100.251C230.864 99.7418 231.359 99.5438 231.783 99.7135C232.581 99.9964 232.793 101.354 232.8 101.411C232.803 101.43 232.802 101.449 232.797 101.467C232.793 101.485 232.785 101.502 232.774 101.518C232.763 101.533 232.748 101.546 232.732 101.555C232.716 101.565 232.698 101.571 232.68 101.574C232.643 101.578 232.607 101.567 232.578 101.545C232.549 101.523 232.529 101.49 232.524 101.453C232.524 101.453 232.326 100.202 231.691 99.9752C231.373 99.8621 230.984 100.025 230.511 100.456C230.133 100.812 229.835 101.243 229.635 101.722C229.944 101.414 230.337 101.204 230.765 101.121C230.863 101.113 230.961 101.125 231.053 101.157C231.146 101.188 231.23 101.239 231.302 101.305C231.316 101.317 231.327 101.333 231.334 101.35C231.342 101.367 231.346 101.385 231.346 101.404C231.346 101.423 231.342 101.441 231.334 101.458C231.327 101.475 231.316 101.49 231.302 101.503C231.276 101.529 231.24 101.543 231.203 101.543C231.166 101.543 231.131 101.529 231.105 101.503C231.062 101.461 231.011 101.429 230.955 101.41C230.899 101.39 230.839 101.383 230.78 101.39C230.264 101.39 229.551 102.097 229.318 102.408C229.306 102.425 229.292 102.439 229.275 102.45C229.258 102.461 229.239 102.469 229.219 102.472Z"
        fill="#1A2E35"
      />
      <path
        d="M214.217 104.481H225.32V115.005C225.32 116.189 224.85 117.324 224.015 118.161C223.179 118.998 222.045 119.468 220.863 119.468H218.681C217.499 119.468 216.365 118.998 215.529 118.161C214.693 117.324 214.224 116.189 214.224 115.005V104.481H214.217Z"
        fill="#FFBCB6"
      />
      <path
        d="M220.206 98.9567H219.33C216.506 98.9567 214.217 101.249 214.217 104.077V108.823C214.217 111.651 216.506 113.944 219.33 113.944H220.206C223.03 113.944 225.32 111.651 225.32 108.823V104.077C225.32 101.249 223.03 98.9567 220.206 98.9567Z"
        fill="#CA6F67"
      />
      <path
        d="M229.57 99.8527C229.772 93.5786 225.646 88.3545 220.355 88.1843C215.065 88.0141 210.613 92.9623 210.411 99.2364C210.21 105.51 214.336 110.735 219.626 110.905C224.917 111.075 229.369 106.127 229.57 99.8527Z"
        fill="#FFBCB6"
      />
      <path
        d="M219.634 104.191C219.317 104.177 219.01 104.071 218.751 103.886C218.727 103.859 218.714 103.824 218.714 103.787C218.714 103.751 218.727 103.716 218.751 103.688C218.778 103.663 218.813 103.648 218.85 103.648C218.887 103.648 218.923 103.663 218.949 103.688C219.204 103.868 219.515 103.948 219.825 103.915C219.96 103.881 220.085 103.818 220.193 103.73C220.3 103.642 220.387 103.531 220.446 103.406C220.513 103.313 220.556 103.205 220.572 103.092C220.588 102.978 220.577 102.863 220.538 102.755C220.304 102.396 219.939 102.142 219.521 102.048C219.491 102.04 219.465 102.023 219.446 101.998C219.426 101.974 219.416 101.944 219.415 101.913L219.33 98.0868C219.33 98.0505 219.344 98.0155 219.369 97.9892C219.394 97.9628 219.428 97.9471 219.465 97.9453C219.501 97.9453 219.536 97.9592 219.562 97.9842C219.588 98.0092 219.604 98.0434 219.606 98.0797L219.662 101.807C220.113 101.926 220.5 102.215 220.743 102.613C220.799 102.76 220.819 102.918 220.802 103.074C220.785 103.231 220.731 103.381 220.644 103.512C220.563 103.672 220.448 103.813 220.306 103.925C220.164 104.036 220 104.115 219.825 104.155C219.763 104.173 219.699 104.185 219.634 104.191Z"
        fill="#1A2E35"
      />
      <path
        d="M216.558 100.104C216.6 99.3591 216.231 98.7321 215.732 98.7036C215.234 98.6751 214.795 99.2558 214.753 100.001C214.71 100.746 215.08 101.372 215.578 101.401C216.077 101.43 216.515 100.849 216.558 100.104Z"
        fill="#1A2E35"
      />
      <path
        d="M224.782 100.103C224.825 99.3582 224.455 98.7312 223.957 98.7027C223.458 98.6742 223.02 99.2549 222.977 99.9998C222.935 100.745 223.304 101.372 223.803 101.4C224.301 101.429 224.74 100.848 224.782 100.103Z"
        fill="#1A2E35"
      />
      <path
        d="M220.206 106.631C219.308 106.572 218.463 106.178 217.84 105.527C217.814 105.501 217.8 105.465 217.8 105.428C217.8 105.391 217.814 105.356 217.84 105.329C217.853 105.316 217.868 105.305 217.885 105.297C217.902 105.29 217.921 105.286 217.939 105.286C217.958 105.286 217.976 105.29 217.993 105.297C218.01 105.305 218.025 105.316 218.038 105.329C218.109 105.414 219.888 107.338 221.838 105.676C221.85 105.662 221.866 105.651 221.883 105.644C221.9 105.636 221.918 105.632 221.937 105.632C221.955 105.632 221.974 105.636 221.991 105.644C222.008 105.651 222.023 105.662 222.036 105.676C222.061 105.702 222.076 105.738 222.076 105.775C222.076 105.812 222.061 105.847 222.036 105.874C221.539 106.342 220.888 106.611 220.206 106.631Z"
        fill="#1A2E35"
      />
      <path
        d="M241.346 126.116L251.17 146.684C251.274 147.028 251.306 147.389 251.266 147.746C251.225 148.102 251.112 148.447 250.935 148.758C250.757 149.07 250.518 149.342 250.232 149.558C249.946 149.774 249.619 149.93 249.271 150.016L243.62 151.381C243.197 151.486 242.752 151.467 242.339 151.326C241.926 151.186 241.562 150.93 241.289 150.588C239.439 148.254 231.083 140.686 232.058 132.39L229.36 126.435L230.709 117.622C236.543 118.831 240.477 123.323 241.346 126.116Z"
        fill="white"
      />
      <path
        d="M197.838 124.85L186.975 144.881C186.851 145.218 186.798 145.577 186.819 145.936C186.839 146.295 186.933 146.646 187.095 146.967C187.256 147.288 187.482 147.572 187.758 147.802C188.034 148.032 188.354 148.203 188.698 148.304L192.936 149.641C193.791 149.888 194.158 152.003 194.744 151.331C196.715 149.103 207.373 139.937 206.829 131.591L209.831 125.791L207.867 117.608C201.941 118.513 198.848 122.106 197.838 124.85Z"
        fill="white"
      />
      <path
        d="M227.672 116.95C226.902 116.809 226.139 116.695 225.383 116.596C224.759 117.503 223.92 118.24 222.941 118.742C221.962 119.245 220.875 119.497 219.775 119.476C218.675 119.455 217.598 119.162 216.639 118.622C215.681 118.083 214.87 117.314 214.28 116.384C213.15 116.483 212.345 116.589 211.957 116.646C212.678 118.106 213.786 119.34 215.16 120.211C216.534 121.083 218.122 121.559 219.748 121.588C221.375 121.616 222.977 121.196 224.381 120.373C225.785 119.55 226.936 118.356 227.707 116.922L227.672 116.95Z"
        fill="#1A2E35"
      />
      <path
        d="M201.398 152.017C201.334 152.017 201.274 151.993 201.228 151.95C201.182 151.906 201.154 151.847 201.15 151.784L200.67 127.651C200.67 127.587 200.695 127.525 200.74 127.479C200.785 127.432 200.846 127.406 200.91 127.404C200.975 127.404 201.036 127.429 201.083 127.474C201.129 127.519 201.156 127.58 201.157 127.644L201.673 151.77C201.673 151.834 201.648 151.896 201.603 151.942C201.558 151.988 201.497 152.015 201.433 152.017H201.398Z"
        fill="black"
      />
      <path
        d="M238.302 152.463C238.239 152.461 238.178 152.435 238.134 152.39C238.089 152.346 238.063 152.285 238.062 152.222L237.581 128.076C237.58 128.044 237.586 128.013 237.597 127.983C237.608 127.953 237.625 127.926 237.647 127.903C237.668 127.88 237.694 127.862 237.723 127.849C237.752 127.836 237.783 127.829 237.814 127.828C237.879 127.828 237.941 127.853 237.987 127.898C238.033 127.943 238.06 128.004 238.062 128.068L238.549 152.194C238.549 152.258 238.524 152.32 238.479 152.367C238.434 152.413 238.373 152.44 238.309 152.441L238.302 152.463Z"
        fill="black"
      />
      <path
        d="M215.177 89.7338C215.177 89.7338 217.642 88.2414 220.086 91.5161C222.17 94.3099 227.672 97.1744 230.935 94.4514C230.935 94.4514 228.859 86.5369 220.757 86.6712C212.656 86.8056 215.177 89.7338 215.177 89.7338Z"
        fill="#1A2E35"
      />
      <path
        d="M215.418 89.6206L213.68 85.9215L214.944 89.7338L215.418 89.6206Z"
        fill="#BABABA"
      />
      <path
        d="M214.676 88.0646C215.298 87.6308 215.978 87.2879 216.696 87.0461C217.419 86.7958 218.178 86.6668 218.942 86.6642C220.493 86.6688 221.981 87.2746 223.095 88.3546C223.624 88.8907 224.124 89.455 224.592 90.045C224.833 90.335 225.087 90.6038 225.341 90.8867C225.595 91.1696 225.864 91.4171 226.139 91.6647C226.684 92.1773 227.288 92.6215 227.94 92.9873C228.264 93.1723 228.607 93.3194 228.964 93.4258C229.32 93.5395 229.698 93.5662 230.066 93.5036C229.693 93.5768 229.307 93.5574 228.943 93.447C228.577 93.3536 228.224 93.2159 227.891 93.0368C227.225 92.68 226.604 92.2456 226.04 91.7425C225.751 91.4949 225.489 91.2261 225.214 90.9645C224.939 90.7028 224.691 90.4269 224.444 90.1369C223.974 89.5596 223.472 89.0094 222.94 88.489L222.509 88.1424L222.29 87.9726L222.05 87.8312L221.577 87.5483C221.414 87.4634 221.238 87.4068 221.075 87.3361C220.739 87.208 220.391 87.1132 220.037 87.0532C219.678 86.9883 219.314 86.9552 218.949 86.9542C218.208 86.9366 217.469 87.0369 216.76 87.2512C216.043 87.4493 215.352 87.7344 214.704 88.1L214.676 88.0646Z"
        fill="#BABABA"
      />
      <path
        d="M214.45 87.5695C215.576 86.2312 217.008 85.1853 218.624 84.5211C219.447 84.2102 220.338 84.1319 221.202 84.2948C222.064 84.4873 222.852 84.9216 223.476 85.5467C224.682 86.8062 225.763 88.1801 226.704 89.6489C227.131 90.3999 227.675 91.078 228.315 91.6576C229.044 92.1179 229.889 92.363 230.751 92.3649C229.869 92.3816 229.001 92.1354 228.258 91.6576C227.598 91.085 227.035 90.409 226.591 89.656C225.652 88.1979 224.583 86.8273 223.399 85.5608C222.797 84.9409 222.03 84.5085 221.188 84.316C220.338 84.1467 219.458 84.2177 218.645 84.5211C217.027 85.1617 215.589 86.1887 214.457 87.5129C214.457 87.5129 214.443 87.5766 214.45 87.5695Z"
        fill="#BABABA"
      />
      <path
        d="M213.758 86.3459C214.596 84.7812 215.967 83.5693 217.621 82.9297C218.454 82.6428 219.346 82.5723 220.213 82.7246C220.429 82.7565 220.642 82.8061 220.849 82.8731C221.059 82.9284 221.267 82.9945 221.471 83.0711C221.876 83.2358 222.272 83.4223 222.657 83.6299C224.18 84.4806 225.552 85.5775 226.718 86.8763C227.319 87.5058 227.856 88.1989 228.498 88.786C228.646 88.9449 228.824 89.0725 229.022 89.1612C229.219 89.2499 229.433 89.2979 229.65 89.3023C230.079 89.2181 230.467 88.9913 230.751 88.6587C230.467 88.9922 230.079 89.2212 229.65 89.3094C229.431 89.3073 229.215 89.2612 229.015 89.1737C228.815 89.0862 228.634 88.9591 228.484 88.8001C227.841 88.2131 227.298 87.527 226.697 86.9046C225.522 85.6174 224.146 84.5304 222.622 83.6865C222.248 83.4672 221.838 83.3258 221.449 83.1419C221.246 83.0699 221.038 83.0085 220.828 82.958C220.624 82.8874 220.413 82.8399 220.199 82.8165C219.345 82.6701 218.468 82.7431 217.65 83.0287C216.034 83.6724 214.699 84.8708 213.885 86.4095L213.758 86.3459Z"
        fill="#BABABA"
      />
    </g>
    <mask
      id="mask1"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="139"
      width="53"
      height="53"
    >
      <path
        d="M26.3449 191.767C40.8948 191.767 52.6898 179.956 52.6898 165.386C52.6898 150.815 40.8948 139.004 26.3449 139.004C11.795 139.004 0 150.815 0 165.386C0 179.956 11.795 191.767 26.3449 191.767Z"
        fill="#FFE0C2"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M26.3449 191.767C40.8948 191.767 52.6898 179.956 52.6898 165.386C52.6898 150.815 40.8948 139.004 26.3449 139.004C11.795 139.004 0 150.815 0 165.386C0 179.956 11.795 191.767 26.3449 191.767Z"
        fill="#FFE0C2"
      />
      <path
        d="M18.0151 159.416C18.0151 159.416 16.8709 165.633 9.3418 170.089C1.31826 174.835 5.72556 190.204 19.6961 190.671C34.8745 191.18 42.4743 171.787 33.8221 156.198L18.0151 159.416Z"
        fill="#FF7A44"
      />
      <path
        d="M20.24 161.991C20.24 161.991 19.2441 167.38 12.725 171.235C5.77504 175.351 9.58906 188.662 21.695 189.066C34.8392 189.504 41.6762 173.116 34.1965 159.607L20.24 161.991Z"
        fill="#A93C1E"
      />
      <path
        d="M25.6361 171.956L17.0122 173.618C14.526 174.099 12.3647 176.525 12.8945 179.008L19.0534 196.831L31.9433 197.319L38.0034 179.072C38.6037 176.568 36.1741 174.057 33.6526 173.555L25.6361 171.956Z"
        fill="white"
      />
      <path
        d="M17.0122 173.618L25.6361 171.956L33.6526 173.555L33.476 173.682C31.0647 175.384 28.1841 176.292 25.234 176.281C22.2838 176.27 19.4104 175.339 17.0122 173.618Z"
        fill="white"
      />
      <path
        d="M19.8939 159.876C19.8939 159.876 16.645 157.131 16.313 160.484C15.981 163.837 19.3854 164.551 20.1764 162.868L19.8939 159.876Z"
        fill="#FFBCB6"
      />
      <path
        d="M19.5054 161.828C19.4906 161.829 19.4758 161.826 19.4622 161.82C19.4487 161.814 19.4369 161.804 19.4277 161.792C19.2653 161.602 18.7214 161.085 18.3966 161.085C18.3543 161.079 18.3111 161.082 18.2706 161.096C18.2301 161.109 18.1933 161.132 18.1635 161.163C18.1438 161.181 18.1179 161.19 18.0914 161.189C18.0649 161.187 18.04 161.176 18.0222 161.156C18.0044 161.136 17.9952 161.11 17.9965 161.084C17.9978 161.057 18.0096 161.032 18.0293 161.014C18.0793 160.968 18.1385 160.933 18.203 160.911C18.2674 160.889 18.3358 160.881 18.4036 160.887C18.7085 160.94 18.9896 161.086 19.2088 161.304C19.0653 160.969 18.8564 160.666 18.5943 160.413C18.2624 160.109 17.9798 159.996 17.7538 160.074C17.3018 160.229 17.1676 161.106 17.1676 161.121C17.166 161.133 17.1617 161.146 17.1549 161.157C17.1482 161.168 17.1392 161.178 17.1285 161.185C17.1178 161.192 17.1057 161.197 17.093 161.2C17.0803 161.202 17.0672 161.201 17.0546 161.198C17.0415 161.197 17.029 161.192 17.0176 161.186C17.0063 161.179 16.9964 161.17 16.9885 161.16C16.9806 161.149 16.9749 161.137 16.9717 161.124C16.9685 161.112 16.9678 161.098 16.9698 161.085C16.9698 161.05 17.1252 160.088 17.6761 159.89C17.9798 159.784 18.3259 159.89 18.7073 160.265C19.0109 160.54 19.2517 160.878 19.4136 161.255L19.6114 161.701C19.6196 161.722 19.6198 161.746 19.6121 161.768C19.6043 161.79 19.5891 161.809 19.569 161.821C19.5597 161.827 19.5491 161.831 19.5381 161.832C19.527 161.833 19.5158 161.832 19.5054 161.828Z"
        fill="#1A2E35"
      />
      <path
        d="M32.7062 160.201C32.7062 160.201 36.1458 157.294 36.5061 160.845C36.8663 164.395 33.243 165.159 32.4025 163.37L32.7062 160.201Z"
        fill="#FFBCB6"
      />
      <path
        d="M33.1158 162.266H33.0664C33.0459 162.253 33.0309 162.233 33.0244 162.21C33.0179 162.186 33.0203 162.161 33.0311 162.139L33.2359 161.665C33.3951 161.273 33.6359 160.918 33.9422 160.626C34.3519 160.251 34.7191 160.109 35.0299 160.222C35.6303 160.435 35.7856 161.446 35.7927 161.496C35.7947 161.508 35.794 161.521 35.7908 161.533C35.7875 161.546 35.7817 161.557 35.7737 161.567C35.7657 161.577 35.7558 161.585 35.7444 161.591C35.7331 161.597 35.7207 161.601 35.7079 161.602C35.6827 161.606 35.6569 161.6 35.6358 161.586C35.6147 161.571 35.6001 161.549 35.5949 161.524C35.5949 161.524 35.4537 160.583 34.9663 160.406C34.7262 160.329 34.4225 160.449 34.0764 160.774C33.7745 161.043 33.5337 161.373 33.3701 161.743C33.6067 161.506 33.9096 161.345 34.2388 161.283C34.3105 161.277 34.3825 161.286 34.4506 161.309C34.5186 161.332 34.5812 161.369 34.6344 161.418C34.6438 161.427 34.6512 161.438 34.6563 161.45C34.6614 161.462 34.664 161.475 34.664 161.488C34.664 161.502 34.6614 161.515 34.6563 161.527C34.6512 161.539 34.6438 161.55 34.6344 161.559C34.6149 161.577 34.5898 161.586 34.5637 161.586C34.5377 161.586 34.5125 161.577 34.4931 161.559C34.4611 161.527 34.4222 161.503 34.3793 161.488C34.3365 161.473 34.2908 161.469 34.2459 161.474C33.8574 161.474 33.3207 162.033 33.1441 162.238C33.1364 162.249 33.1268 162.259 33.1158 162.266Z"
        fill="#1A2E35"
      />
      <path
        d="M21.8787 163.773H30.1989V171.426C30.1989 172.377 29.8216 173.289 29.15 173.961C28.4785 174.634 27.5676 175.012 26.6179 175.012H25.4596C24.5099 175.012 23.599 174.634 22.9275 173.961C22.2559 173.289 21.8787 172.377 21.8787 171.426V163.773Z"
        fill="#FFBCB6"
      />
      <path
        d="M26.625 159.635H25.4596C23.4819 159.635 21.8787 161.241 21.8787 163.221V167.288C21.8787 169.269 23.4819 170.874 25.4596 170.874H26.625C28.6027 170.874 30.2059 169.269 30.2059 167.288V163.221C30.2059 161.241 28.6027 159.635 26.625 159.635Z"
        fill="#CA6F67"
      />
      <path
        d="M31.3792 150.551C32.8772 148.121 32.1192 144.932 29.6862 143.428C27.2533 141.924 24.0667 142.674 22.5687 145.104C21.0708 147.533 21.8287 150.722 24.2617 152.226C26.6947 153.731 29.8813 152.98 31.3792 150.551Z"
        fill="#FF7A44"
      />
      <path
        d="M33.3861 160.314C33.537 155.609 30.4451 151.692 26.4801 151.564C22.515 151.437 19.1783 155.147 19.0274 159.852C18.8765 164.556 21.9685 168.473 25.9335 168.601C29.8986 168.729 33.2352 165.018 33.3861 160.314Z"
        fill="#FFBCB6"
      />
      <path
        d="M25.9398 163.554C25.7031 163.546 25.4742 163.467 25.2829 163.327C25.2737 163.319 25.2664 163.308 25.2614 163.297C25.2564 163.285 25.2538 163.273 25.2538 163.26C25.2538 163.248 25.2564 163.235 25.2614 163.224C25.2664 163.212 25.2737 163.202 25.2829 163.193C25.2921 163.184 25.3031 163.176 25.3153 163.171C25.3274 163.166 25.3404 163.163 25.3536 163.163C25.3667 163.163 25.3797 163.166 25.3919 163.171C25.404 163.176 25.415 163.184 25.4242 163.193C25.6143 163.33 25.8479 163.393 26.081 163.37C26.184 163.345 26.28 163.297 26.3619 163.229C26.4439 163.162 26.5096 163.077 26.5543 162.981C26.604 162.911 26.6358 162.83 26.6469 162.745C26.658 162.66 26.648 162.573 26.6178 162.493C26.4695 162.125 25.8621 161.969 25.855 161.969C25.8345 161.965 25.8163 161.953 25.8034 161.936C25.7905 161.919 25.7838 161.899 25.7844 161.877L25.735 159.006C25.7349 158.979 25.7451 158.953 25.7635 158.933C25.7818 158.914 25.807 158.902 25.8338 158.9C25.8601 158.9 25.8852 158.91 25.9038 158.929C25.9223 158.947 25.9327 158.973 25.9327 158.999L25.9751 161.828C26.1471 161.869 26.309 161.945 26.451 162.051C26.593 162.157 26.7122 162.29 26.8015 162.443C26.8443 162.552 26.8597 162.669 26.8461 162.785C26.8326 162.901 26.7905 163.012 26.7238 163.108C26.664 163.228 26.5786 163.333 26.4736 163.416C26.3686 163.5 26.2467 163.558 26.1164 163.589C26.056 163.587 25.9964 163.575 25.9398 163.554Z"
        fill="#1A2E35"
      />
      <path
        d="M23.4813 160.872C23.5132 160.314 23.2359 159.845 22.862 159.823C22.4881 159.802 22.1593 160.237 22.1274 160.794C22.0956 161.352 22.3729 161.822 22.7468 161.843C23.1206 161.864 23.4495 161.43 23.4813 160.872Z"
        fill="#1A2E35"
      />
      <path
        d="M29.6453 161.242C29.6771 160.685 29.3998 160.215 29.0259 160.194C28.6521 160.173 28.3232 160.607 28.2914 161.165C28.2596 161.723 28.5368 162.192 28.9107 162.214C29.2846 162.235 29.6134 161.8 29.6453 161.242Z"
        fill="#1A2E35"
      />
      <path
        d="M26.3636 165.386C25.6924 165.341 25.0624 165.045 24.5978 164.558C24.5805 164.539 24.5709 164.513 24.5709 164.487C24.5709 164.461 24.5805 164.436 24.5978 164.417C24.607 164.407 24.618 164.4 24.6302 164.395C24.6423 164.39 24.6553 164.387 24.6685 164.387C24.6816 164.387 24.6946 164.39 24.7068 164.395C24.7189 164.4 24.7299 164.407 24.7391 164.417C25.0822 164.822 25.5702 165.076 26.0984 165.125C26.6265 165.174 27.1528 165.014 27.5643 164.678C27.5736 164.667 27.5851 164.659 27.598 164.653C27.611 164.647 27.6251 164.644 27.6394 164.644C27.6537 164.644 27.6678 164.647 27.6808 164.653C27.6938 164.659 27.7053 164.667 27.7146 164.678C27.7239 164.689 27.7308 164.702 27.7347 164.716C27.7386 164.729 27.7394 164.744 27.7372 164.758C27.735 164.772 27.7298 164.786 27.7219 164.797C27.714 164.809 27.7036 164.819 27.6914 164.827C27.3275 165.163 26.8578 165.36 26.3636 165.386Z"
        fill="#1A2E35"
      />
      <path
        d="M31.6468 159.791C29.1888 160.435 25.7068 159.791 25.7068 159.791C25.7318 158.788 25.5315 157.792 25.1206 156.877L25.0075 156.622C25.0639 157.664 24.8878 158.706 24.492 159.671H24.3083C19.6538 159.671 18.2412 159.084 18.2412 159.084C18.2412 159.084 19.5761 150.356 26.7168 150.313C33.8575 150.271 34.705 159.749 34.705 159.749H32.8192L32.7768 159.579C32.6297 158.898 32.3222 158.263 31.8798 157.726C31.8815 158.406 31.8248 159.085 31.7103 159.756L31.6468 159.791Z"
        fill="#FF7A44"
      />
      <path
        d="M22.5566 145.122C22.5566 145.122 26.4484 149.366 31.5196 145.405C31.5196 145.405 28.2635 150.525 22.2318 145.737L22.5566 145.122Z"
        fill="#FFBFAE"
      />
      <path
        d="M23.4112 144.068C23.9373 144.544 24.5268 144.943 25.1629 145.256C25.8013 145.572 26.4854 145.784 27.1899 145.886C27.5408 145.935 27.8951 145.957 28.2494 145.949C28.6057 145.931 28.96 145.883 29.3088 145.808C30.0067 145.65 30.6761 145.385 31.2935 145.023C30.681 145.4 30.0139 145.679 29.3159 145.85C28.9685 145.939 28.6139 145.995 28.2564 146.02C27.8959 146.037 27.5345 146.025 27.1758 145.985C26.4573 145.895 25.757 145.694 25.0993 145.391C24.439 145.086 23.8255 144.688 23.277 144.21L23.4112 144.068Z"
        fill="#FFBFAE"
      />
      <path
        d="M32.2047 147.597C31.598 148.261 30.8811 148.813 30.0858 149.231C29.299 149.665 28.4031 149.859 27.5078 149.79C26.618 149.678 25.7855 149.29 25.1276 148.68C24.4764 148.083 23.9213 147.388 23.4819 146.621C23.9329 147.378 24.5002 148.058 25.1629 148.637C25.815 149.23 26.6389 149.599 27.5149 149.691C28.3885 149.744 29.2582 149.537 30.0152 149.097C30.7784 148.674 31.4639 148.124 32.0423 147.47L32.2047 147.597Z"
        fill="#FFBFAE"
      />
      <path
        d="M31.5125 152.357C31.5125 152.357 27.0275 148.354 22.4154 151.601C23.8963 151.061 25.4764 150.849 27.0468 150.98C28.6173 151.111 30.1408 151.581 31.5125 152.357Z"
        fill="#1A2E35"
      />
      <path
        d="M21.8787 147.725C22.1366 148.202 22.4733 148.633 22.8745 148.998C23.2748 149.372 23.7268 149.686 24.2165 149.932C24.7092 150.175 25.2315 150.354 25.7704 150.462C26.0384 150.517 26.3097 150.555 26.5826 150.575C26.8581 150.575 27.1335 150.575 27.409 150.575C27.1335 150.575 26.8581 150.575 26.5826 150.575C26.3058 150.559 26.03 150.528 25.7562 150.483C25.2087 150.392 24.6763 150.225 24.1741 149.988C23.6605 149.756 23.1843 149.449 22.7615 149.076C22.3337 148.71 21.9707 148.275 21.688 147.788C21.6785 147.766 21.6774 147.741 21.685 147.718C21.6926 147.696 21.7082 147.676 21.729 147.664C21.7499 147.652 21.7744 147.648 21.7981 147.652C21.8217 147.657 21.8428 147.67 21.8575 147.689L21.8787 147.725Z"
        fill="#FFBFAE"
      />
      <path
        d="M27.8963 159.989C28.1544 159.201 28.3111 158.384 28.3625 157.556C28.4218 156.726 28.36 155.892 28.1788 155.081C27.9998 154.263 27.6666 153.488 27.1971 152.796C26.7235 152.105 26.1331 151.502 25.4525 151.014C26.1429 151.491 26.7451 152.084 27.2324 152.768C27.7265 153.453 28.0794 154.229 28.2706 155.052C28.4703 155.873 28.5512 156.719 28.5108 157.563C28.473 158.41 28.3282 159.249 28.0799 160.06C28.0715 160.085 28.0534 160.106 28.0295 160.118C28.0057 160.13 27.9781 160.132 27.9528 160.123C27.9275 160.115 27.9066 160.097 27.8947 160.073C27.8828 160.049 27.8808 160.022 27.8892 159.996L27.8963 159.989Z"
        fill="#FFBFAE"
      />
      <path
        d="M22.599 159.756C22.3513 158.945 22.2065 158.106 22.1682 157.259C22.1255 156.415 22.2039 155.57 22.4012 154.748C22.5983 153.926 22.9534 153.15 23.4466 152.464C23.9338 151.78 24.5361 151.186 25.2264 150.709C24.5458 151.197 23.9555 151.801 23.4819 152.492C23.0109 153.18 22.6775 153.954 22.5001 154.769C22.319 155.583 22.2571 156.42 22.3165 157.252C22.3679 158.079 22.5245 158.897 22.7826 159.685C22.7919 159.709 22.7911 159.736 22.7805 159.76C22.7699 159.784 22.7504 159.803 22.7261 159.812C22.7008 159.819 22.6738 159.817 22.6503 159.805C22.6268 159.793 22.6085 159.773 22.599 159.749V159.756Z"
        fill="#FFBFAE"
      />
      <path
        d="M26.3495 150.773C27.148 151.21 27.8844 151.753 28.539 152.386C29.1978 153.019 29.7532 153.751 30.1847 154.557C30.2925 154.757 30.3892 154.962 30.4743 155.172L30.7073 155.823C30.7709 156.042 30.8062 156.269 30.8627 156.488C30.9136 156.72 30.9419 156.957 30.9475 157.195C31.0183 158.111 30.9323 159.032 30.6932 159.918C30.6923 159.933 30.6884 159.946 30.6816 159.959C30.6748 159.972 30.6654 159.983 30.654 159.991C30.6426 160 30.6294 160.006 30.6154 160.009C30.6015 160.012 30.587 160.012 30.5731 160.009C30.5591 160.006 30.546 160 30.5347 159.991C30.5233 159.982 30.5139 159.971 30.5072 159.959C30.5005 159.946 30.4966 159.932 30.4958 159.918C30.495 159.903 30.4973 159.889 30.5025 159.876C30.7545 159.011 30.8572 158.109 30.8062 157.209C30.8017 156.986 30.7757 156.763 30.7285 156.545C30.6791 156.325 30.6438 156.099 30.5873 155.88L30.3754 155.243C30.3048 155.031 30.1847 154.833 30.0929 154.628C29.6825 153.821 29.146 153.086 28.5037 152.449C27.8639 151.796 27.1394 151.233 26.3495 150.773Z"
        fill="#FFBFAE"
      />
      <path
        d="M34.4719 159.791C34.3635 158.798 34.126 157.823 33.7656 156.891C33.2453 155.477 32.4178 154.197 31.343 153.143C30.9757 152.802 30.5812 152.492 30.1635 152.216C29.3275 151.658 28.4102 151.233 27.4442 150.957C28.9074 151.347 30.2594 152.073 31.3924 153.079C31.7588 153.428 32.0988 153.804 32.4095 154.203C33.0249 155.005 33.5265 155.888 33.8998 156.828C34.2784 157.771 34.5325 158.76 34.6555 159.77C34.6575 159.782 34.6568 159.795 34.6536 159.808C34.6503 159.82 34.6445 159.832 34.6365 159.842C34.6285 159.852 34.6186 159.86 34.6072 159.866C34.5959 159.872 34.5835 159.875 34.5708 159.876C34.5578 159.878 34.5445 159.877 34.5317 159.874C34.519 159.871 34.507 159.865 34.4964 159.857C34.4859 159.849 34.4771 159.839 34.4704 159.828C34.4638 159.817 34.4595 159.804 34.4578 159.791H34.4719Z"
        fill="#FFBFAE"
      />
      <path
        d="M40.9981 179.432L47.8421 193.769C47.9156 194.008 47.9394 194.26 47.912 194.508C47.8846 194.757 47.8066 194.998 47.6829 195.215C47.5591 195.432 47.3922 195.622 47.1924 195.772C46.9927 195.923 46.7642 196.03 46.5213 196.089L42.566 197.051C42.2702 197.121 41.9607 197.105 41.6732 197.006C41.3857 196.907 41.1321 196.729 40.9416 196.492C39.6561 194.865 33.8291 189.596 34.5072 183.803L32.6284 179.659L33.5678 173.519C37.6502 174.396 40.3906 177.487 40.9981 179.432Z"
        fill="white"
      />
      <path
        d="M35.037 188.146H34.9734C34.9525 188.138 34.9334 188.126 34.9171 188.111C34.9008 188.096 34.8877 188.077 34.8785 188.057C34.8693 188.037 34.8642 188.015 34.8635 187.992C34.8629 187.97 34.8666 187.948 34.8745 187.927L37.1488 182.198C37.1661 182.155 37.1995 182.121 37.2417 182.102C37.2839 182.084 37.3317 182.082 37.3748 182.099C37.4163 182.117 37.4489 182.151 37.466 182.193C37.4831 182.236 37.4834 182.283 37.4666 182.325L35.1782 188.033C35.1678 188.063 35.1489 188.09 35.1239 188.11C35.0989 188.13 35.0688 188.142 35.037 188.146Z"
        fill="black"
      />
      <path
        d="M10.6697 178.555L3.09818 192.517C3.01273 192.752 2.97625 193.002 2.99098 193.252C3.00571 193.501 3.07134 193.745 3.18384 193.969C3.29633 194.192 3.4533 194.39 3.64507 194.55C3.83684 194.71 4.05935 194.83 4.29889 194.9L7.24415 195.834C7.83744 196.011 8.09171 197.482 8.50137 197.015C9.91396 195.459 17.3089 189.072 16.9275 183.251L19.0464 179.206L17.6762 173.505C13.5302 174.135 11.376 176.638 10.6697 178.555Z"
        fill="white"
      />
      <path
        d="M16.1858 187.559C16.1497 187.558 16.1148 187.546 16.0857 187.525C16.0567 187.503 16.0349 187.473 16.0234 187.439L14.0457 181.604C14.0322 181.562 14.0358 181.516 14.0556 181.476C14.0754 181.436 14.1099 181.406 14.1517 181.392C14.1729 181.383 14.1958 181.378 14.2187 181.379C14.2417 181.38 14.2643 181.385 14.285 181.395C14.3057 181.405 14.324 181.42 14.3388 181.437C14.3536 181.455 14.3644 181.476 14.3706 181.498L16.3483 187.333C16.362 187.376 16.3587 187.423 16.339 187.464C16.3193 187.504 16.2847 187.536 16.2423 187.552L16.1858 187.559Z"
        fill="black"
      />
      <path
        d="M31.9787 173.074C31.3995 172.968 30.8203 172.883 30.2553 172.812C29.7784 173.486 29.1442 174.033 28.4079 174.405C27.6716 174.777 26.8556 174.963 26.031 174.947C25.2064 174.931 24.3982 174.713 23.6769 174.313C22.9556 173.912 22.3431 173.341 21.8927 172.649C21.0452 172.727 20.4378 172.805 20.1411 172.847C20.6941 173.936 21.5328 174.854 22.5672 175.502C23.6016 176.149 24.7926 176.503 26.0124 176.524C27.2322 176.545 28.4347 176.233 29.4908 175.621C30.5469 175.01 31.4167 174.122 32.0069 173.053L31.9787 173.074Z"
        fill="#1A2E35"
      />
      <path
        d="M17.6126 163.455C17.5316 164.39 17.2342 165.293 16.7438 166.093C16.2696 166.899 15.674 167.626 14.9781 168.25C14.6339 168.564 14.2731 168.859 13.8975 169.134C13.5019 169.382 13.1276 169.672 12.7109 169.884C11.8847 170.297 11.1096 170.805 10.4013 171.398C9.05725 172.674 8.2131 174.39 8.02106 176.235C7.89047 177.15 7.89047 178.079 8.02106 178.994C8.09231 179.451 8.2058 179.901 8.36008 180.338C8.43191 180.555 8.52169 180.765 8.62848 180.967C8.73474 181.175 8.86513 181.37 9.01694 181.547C8.41978 180.817 8.03599 179.936 7.90805 179.001C7.73952 178.068 7.70385 177.115 7.80211 176.172C7.91018 175.236 8.17523 174.325 8.5861 173.477L8.91806 172.855L9.32771 172.275C9.45861 172.08 9.61017 171.899 9.77974 171.737C9.9356 171.563 10.1006 171.398 10.2741 171.242C10.9977 170.649 11.7869 170.141 12.6261 169.728C13.0428 169.523 13.4172 169.24 13.8127 169.021C14.2082 168.802 14.5614 168.477 14.8933 168.158C15.5912 167.546 16.1914 166.83 16.6732 166.036C17.1698 165.248 17.4769 164.354 17.5702 163.427L17.6126 163.455Z"
        fill="#FFBFAE"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="118.026"
        y1="55.8923"
        x2="118.026"
        y2="223.968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3F1FF" />
        <stop offset="1" stopColor="#A6D4FF" />
      </linearGradient>
    </defs>
  </svg>
);
