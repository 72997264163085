import React from 'react';

import { Route, Switch } from 'react-router-dom';

// import Home from './pages/Home';
import Condition from './pages/Register/Condition';
import Profile from './pages/Register/Profile';
import RegisterSuccess from './pages/Register/Success';
import RegisterUnverified from './pages/Register/Unverified';
import Report from './pages/Reports';
import ReportSuccess from './pages/Reports/Success';
import FollowUp from './pages/FollowUp';
import CaseDetail from './pages/FollowUp/CaseDetail';
import NoCase from './pages/FollowUp/NoCase';
import AddContent from './pages/FollowUp/AddContent';
import Dashboard from './pages/Dashboard';
import MyProfile from './pages/MyProfile';
import NoMatch from './pages/404';
import Message from './pages/Message';

export default () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={Home} /> */}
      <Route exact path="/my-profile" component={MyProfile} />
      <Route exact path="/register/condition" component={Condition} />
      <Route exact path="/register/profile" component={Profile} />
      <Route exact path="/register/success" component={RegisterSuccess} />
      <Route exact path="/register/unverified" component={RegisterUnverified} />
      <Route exact path="/reports" component={Report} />
      <Route exact path="/reports/success" component={ReportSuccess} />
      <Route exact path="/follow-up" component={FollowUp} />
      <Route exact path="/follow-up/no-case" component={NoCase} />
      <Route exact path="/follow-up/:id/detail" component={CaseDetail} />
      <Route exact path="/follow-up/:id/add-content" component={AddContent} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/message" component={Message} />

      <Route component={NoMatch} />
    </Switch>
  );
};
