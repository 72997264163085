import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
// import map from 'lodash/map';
import get from 'lodash/get';
import { Form, Input, Modal } from 'antd';

import Header from '../../../components/Header';
import UploadFiles from '../../../components/UploadFiles';
import Button from '../../../components/Button';
import useLoading from '../../../hooks/loading';
import { alert } from '../../../utils';
import { createIncidentNote } from '../../../service';

import Style from './style';

const AddContent = (props) => {
  const { history, match } = props;
  const loading = useLoading();
  // const [state, setState] = React.useState([]);
  const [form] = Form.useForm();
  const id = get(match, 'params.id');

  const loadData = async () => {
    // loading.open();
    // try {
    //   const data = [
    //     {
    //       content: 'รายละเอียด',
    //       id,
    //       createdAt: moment().format()
    //     }
    //   ];
    //   // setState(data);
    // } catch (err) {
    //   console.log('loadData >>', err);
    // }
    loading.close();
  };

  React.useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  const handleBack = () => {
    loading.open();
    // แสดง loading ก่อนค่อยเปลียนหน้า
    setTimeout(() => {
      history.push('/follow-up');
    }, 300);
  };

  const handleSubmit = async () => {
    try {
      const res = await createIncidentNote(id, form.getFieldsValue());
      history.push(`/follow-up/${id}/detail`);
      console.log('res >>', res);
    } catch (err) {
      console.log('handleSubmit >>', err);
      alert.error(get(err, 'errorMessage', err));
    }
  };

  const onFinish = () => {
    Modal.confirm({
      title: '',
      centered: true,
      content: <div className="content-confirm">ยืนยันการเพิ่มข้อมูล</div>,
      okText: 'ยืนยัน',
      cancelText: 'กลับ',
      onOk: handleSubmit
    });
  };

  return (
    <Style>
      <Header title="เพิ่มข้อมูล" onBack={handleBack} />
      <div className="container">
        <h1>แจ้งรายละเอียดเหตุการณ์</h1>
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          initialValues={{
            detail: '',
            files: []
          }}
        >
          <Form.Item name="files" label="แนบรูปภาพหรือคลิปวิดีโอ">
            <UploadFiles />
          </Form.Item>
          <Form.Item
            name="detail"
            label="รายละเอียดความเสี่ยง"
            rules={[
              {
                required: true,
                message: 'กรุณาระบุรายละเอียดความเสี่ยง'
              }
            ]}
          >
            <Input.TextArea
              rows={5}
              placeholder="รายละเอียดความเสี่ยง"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <div className="wrap-button">
              <Button primary type="submit" className="button-submit">
                เพิ่มข้อมูล
              </Button>
            </div>
          </Form.Item>
        </Form>
        {/* <ul className="list-content">
          {map(state, (data) => {
            const { content, createdAt, id } = data;
            return (
              <li key={id}>
                <div className="title">วันที่ เวลา</div>
                <p>{content}</p>
                <span>{getThaiDate(createdAt)}</span>
              </li>
            );
          })}
        </ul> */}
      </div>
    </Style>
  );
};

AddContent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape().isRequired
};

AddContent.defaultProps = {};

export default AddContent;
