import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240"
    height="228"
    viewBox="0 0 240 228"
    fill="none"
  >
    <path
      d="M35 88C35 88 35.0571 83.8621 29.2422 84.7586C29.2422 84.7586 27.3372 82 24.3763 82C21.6294 82 20.3594 84.5586 20.3594 84.5586C20.3594 84.5586 18.2189 84.5586 18.1404 86.5724C17.671 86.5502 17.2071 86.678 16.8202 86.936C16.4334 87.194 16.1451 87.5679 16 88H35Z"
      fill="white"
    />
    <path
      d="M128 223C189.856 223 240 173.08 240 111.5C240 49.9203 189.856 0 128 0C66.1441 0 16 49.9203 16 111.5C16 173.08 66.1441 223 128 223Z"
      fill="url(#paint0_linear)"
    />

    <path
      d="M172 75.5036V29H80V75.5036C80 97.6431 98.9512 116.068 120.042 119.952C121.103 120.153 122.06 120.718 122.747 121.549C123.434 122.379 123.808 123.423 123.804 124.5C123.808 125.577 123.434 126.621 122.747 127.451C122.06 128.282 121.103 128.847 120.042 129.048C98.9512 132.932 80 151.357 80 173.504V220H172V173.504C172 151.357 154.041 132.932 132.957 129.048C131.896 128.847 130.939 128.282 130.252 127.451C129.565 126.621 129.191 125.577 129.195 124.5C129.191 123.423 129.565 122.379 130.252 121.549C130.939 120.718 131.896 120.153 132.957 119.952C154.041 116.103 172 97.6431 172 75.5036Z"
      fill="white"
    />
    <path
      d="M80 220H172L137.994 201.975C134.308 200.021 130.205 199 126.039 199C121.873 199 117.77 200.021 114.084 201.975L80 220Z"
      fill="#FF7A44"
    />
    <path
      d="M115.842 70.9596C100.203 60.8385 86.8453 60.9094 80 61.9576V75.4146C80 97.6187 98.9556 116.097 120.051 119.993C121.112 120.194 122.07 120.761 122.757 121.594C123.444 122.427 123.818 123.474 123.814 124.554L125.146 204L127.272 203.816L129.185 124.533C129.181 123.453 129.555 122.406 130.242 121.573C130.93 120.74 131.887 120.173 132.948 119.972C154.036 116.076 172 97.5975 172 75.3934V57.0706C148.729 55.4345 134.301 82.908 115.842 70.9596Z"
      fill="#FF7A44"
    />
    <path d="M192 220H64V228H192V220Z" fill="#1A2E35" />
    <path d="M192 23H64V32H192V23Z" fill="#1A2E35" />
    <path
      d="M119.5 208C119.776 208 120 207.776 120 207.5C120 207.224 119.776 207 119.5 207C119.224 207 119 207.224 119 207.5C119 207.776 119.224 208 119.5 208Z"
      fill="#1A2E35"
    />
    <path
      d="M115.5 102C115.776 102 116 101.776 116 101.5C116 101.224 115.776 101 115.5 101C115.224 101 115 101.224 115 101.5C115 101.776 115.224 102 115.5 102Z"
      fill="#1A2E35"
    />
    <path
      d="M102.5 104C102.776 104 103 103.776 103 103.5C103 103.224 102.776 103 102.5 103C102.224 103 102 103.224 102 103.5C102 103.776 102.224 104 102.5 104Z"
      fill="#1A2E35"
    />
    <path
      d="M109.5 78C109.776 78 110 77.7761 110 77.5C110 77.2239 109.776 77 109.5 77C109.224 77 109 77.2239 109 77.5C109 77.7761 109.224 78 109.5 78Z"
      fill="#1A2E35"
    />
    <path
      d="M127.5 117C127.776 117 128 116.776 128 116.5C128 116.224 127.776 116 127.5 116C127.224 116 127 116.224 127 116.5C127 116.776 127.224 117 127.5 117Z"
      fill="#1A2E35"
    />
    <path
      d="M154.5 216C154.776 216 155 215.776 155 215.5C155 215.224 154.776 215 154.5 215C154.224 215 154 215.224 154 215.5C154 215.776 154.224 216 154.5 216Z"
      fill="#1A2E35"
    />
    <path
      d="M109.5 216C109.776 216 110 215.776 110 215.5C110 215.224 109.776 215 109.5 215C109.224 215 109 215.224 109 215.5C109 215.776 109.224 216 109.5 216Z"
      fill="#1A2E35"
    />
    <path
      d="M128.5 144C128.776 144 129 143.776 129 143.5C129 143.224 128.776 143 128.5 143C128.224 143 128 143.224 128 143.5C128 143.776 128.224 144 128.5 144Z"
      fill="#1A2E35"
    />
    <path
      d="M128.5 204C128.776 204 129 203.776 129 203.5C129 203.224 128.776 203 128.5 203C128.224 203 128 203.224 128 203.5C128 203.776 128.224 204 128.5 204Z"
      fill="#1A2E35"
    />
    <path
      d="M141.5 111C141.776 111 142 110.776 142 110.5C142 110.224 141.776 110 141.5 110C141.224 110 141 110.224 141 110.5C141 110.776 141.224 111 141.5 111Z"
      fill="#1A2E35"
    />
    <path
      d="M97.5 216C97.7761 216 98 215.776 98 215.5C98 215.224 97.7761 215 97.5 215C97.2239 215 97 215.224 97 215.5C97 215.776 97.2239 216 97.5 216Z"
      fill="#1A2E35"
    />
    <path
      d="M127.5 165C127.776 165 128 164.776 128 164.5C128 164.224 127.776 164 127.5 164C127.224 164 127 164.224 127 164.5C127 164.776 127.224 165 127.5 165Z"
      fill="#1A2E35"
    />
    <path
      d="M139.5 216C139.776 216 140 215.776 140 215.5C140 215.224 139.776 215 139.5 215C139.224 215 139 215.224 139 215.5C139 215.776 139.224 216 139.5 216Z"
      fill="#1A2E35"
    />
    <path
      d="M126.5 216C126.776 216 127 215.776 127 215.5C127 215.224 126.776 215 126.5 215C126.224 215 126 215.224 126 215.5C126 215.776 126.224 216 126.5 216Z"
      fill="#1A2E35"
    />
    <path
      d="M146.5 104C146.776 104 147 103.776 147 103.5C147 103.224 146.776 103 146.5 103C146.224 103 146 103.224 146 103.5C146 103.776 146.224 104 146.5 104Z"
      fill="#1A2E35"
    />
    <path
      d="M91.5 77C91.7761 77 92 76.7761 92 76.5C92 76.2239 91.7761 76 91.5 76C91.2239 76 91 76.2239 91 76.5C91 76.7761 91.2239 77 91.5 77Z"
      fill="#1A2E35"
    />
    <path
      d="M127.5 97C127.776 97 128 96.7761 128 96.5C128 96.2239 127.776 96 127.5 96C127.224 96 127 96.2239 127 96.5C127 96.7761 127.224 97 127.5 97Z"
      fill="#1A2E35"
    />
    <path
      d="M139.5 97C139.776 97 140 96.7761 140 96.5C140 96.2239 139.776 96 139.5 96C139.224 96 139 96.2239 139 96.5C139 96.7761 139.224 97 139.5 97Z"
      fill="#1A2E35"
    />
    <path
      d="M134.5 84C134.776 84 135 83.7761 135 83.5C135 83.2239 134.776 83 134.5 83C134.224 83 134 83.2239 134 83.5C134 83.7761 134.224 84 134.5 84Z"
      fill="#1A2E35"
    />
    <path
      d="M154.5 93C154.776 93 155 92.7761 155 92.5C155 92.2239 154.776 92 154.5 92C154.224 92 154 92.2239 154 92.5C154 92.7761 154.224 93 154.5 93Z"
      fill="#1A2E35"
    />
    <path
      d="M110.5 92C110.776 92 111 91.7761 111 91.5C111 91.2239 110.776 91 110.5 91C110.224 91 110 91.2239 110 91.5C110 91.7761 110.224 92 110.5 92Z"
      fill="#1A2E35"
    />
    <path
      d="M93.5 87C93.7761 87 94 86.7761 94 86.5C94 86.2239 93.7761 86 93.5 86C93.2239 86 93 86.2239 93 86.5C93 86.7761 93.2239 87 93.5 87Z"
      fill="#1A2E35"
    />
    <path
      id="cloud"
      d="M230 82C230 82 230.091 74.4633 219.404 76.0561C219.404 76.0561 215.889 71 210.461 71C205.406 71 203.072 75.6833 203.072 75.6833C203.072 75.6833 199.085 75.6833 198.987 79.3839C198.115 79.3286 197.248 79.5562 196.526 80.0302C195.804 80.5043 195.266 81.1977 195 82H230Z"
      fill="white"
    />
    <path
      id="cloud-1"
      d="M79.9999 129C79.9999 129 80.2265 111.194 55.7783 114.963C55.7783 114.963 47.7776 103 35.3305 103C23.7755 103 18.4441 114.073 18.4441 114.073C18.4441 114.073 9.33179 114.073 9.1123 122.818C9.1123 122.818 2.2232 122.218 0 129H79.9999Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="128"
        y1="126.625"
        x2="128"
        y2="223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDEBD6" />
        <stop offset="1" stopColor="#FF937E" />
      </linearGradient>
    </defs>
  </svg>
);
