import styled from '@emotion/styled';

export default styled.div`
  label: page-404;
  background: #fff;
  font-family: Kanit;
  > .container {
    max-width: 370px;
    height: 100vh;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #393f4d;
    position: relative;
    h1 {
      font-weight: 500;
      font-size: 32px;
      margin-bottom: 10px;
      margin-top: 90px;
    }
    p {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 90px;
    }
    button {
      width: 310px;
    }
    @media (max-width: 425px) {
      max-width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      h1 {
        margin-top: 80px;
      }
      p {
        margin-bottom: 80px;
      }
      button {
        width: 100%;
      }
    }

  }
`;
