import styled from '@emotion/styled';

export default styled.div`
  label: page-register-profile;
  background: #fff;
  font-family: Kanit;

  padding-bottom: 50px;
  background: #fffaf5;
  @media (max-width: 425px) {
    background: #fff;
  }
  section.profile-image {
    background: #fffaf5;
    padding: 40px 20px 20px;
    .wrap-image {
      max-width: 130px;
      max-height: 130px;
      margin: auto;
    }
  }
  .container {
    max-width: 400px;
    color: #393f4d;
    position: relative;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 2px 10px #fdebd6;
    border-radius: 20px;
    padding: 20px 30px;
    @media (max-width: 425px) {
      max-width: 100%;
    }
    h1 {
      margin-bottom: 12px;
    }
    .group-phone {
      .ant-select {
        flex: 0 0 100px;
        .ant-select-selection-item img {
          margin-right: 6px;
          max-width: 20px;
          max-height: 15px;
        }
      }
    }
    .button-submit {
      display: block;
      width: 100%;
    }
  }
`;
