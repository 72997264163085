import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import Button from '../Button';

import { uploadFile } from '../../service';

import Style from './style';

const IconClose = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4321 16.9319L3.5744 4.07406"
      stroke="#393F4D"
      strokeOpacity="0.5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3.57422 16.9319L16.4319 4.07406"
      stroke="#393F4D"
      strokeOpacity="0.5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
const UploadFiles = (props) => {
  const { value, onChange } = props;

  const [fileLoading, setFileLoading] = React.useState();
  const [pewview, setPewview] = React.useState({});

  const handleInputChange = async (e) => {
    const files = e.target.files;
    setFileLoading(files);
    try {
      const uploads = map(files, (file) => uploadFile(file));
      const media = await Promise.all(uploads);
      onChange([...(value || []), ...map(media, (obj) => obj.data)]);
    } catch (err) {
      console.error('upload error', err);
    }
    setFileLoading();
  };

  const handlePreview = (src) => {
    if (typeof src === 'object') {
      setPewview({
        type: src.mimeType && /^video/.test(src.mimeType) ? 'video' : 'image',
        src: src.url
      });
    } else {
      setPewview({
        type: /.mp4$/.test(src) ? 'video' : 'image',
        src
      });
    }
  };

  const handleRemoveFile = (src) => {
    onChange(filter(value, (value) => value.url !== src));
  };

  const renderMedia = (src = '') => {
    /*
    src my be returned as object
    {
      url: 'http://localhost:3001/files/from-token?fileToken=4YlcJnfbi%2B0wm3IALkPdpc1SUJB82E3Mn3RS9JupeN7nF%2FwXuKLkkOCCuHb5jkf6AEu64cEYNvHQpxSWD5eGhGpqo%2BhGzmrE6SCzX4WGwqP6JHJJNZAwuH1GOGorkHCjdz8XS4LhvFWO954cmJiKAUmsTpRdm2tuGFfCFN3%2FuSYyAWKqEYRW5OLeEIuIbnh7bsSIfL8oXvLevVxy69o0qXXcAuHkIR3Vg6qWmyV1auA%3D',
      size: 1869821,
      fileName: 'earth_(1)_1633065057359DirbMh.mp4',
      mimeType: 'video/mp4'
    }
    */
    const url = typeof src === 'object' ? src.url : src;
    const splitLink = url.split('/');
    const fileName = typeof src === 'object' ? src.name : get(splitLink, splitLink.length - 1);
    let media = '';
    const isVideo = /.mp4$/.test(url) || (src.mimeType && /^video/.test(src.mimeType));
    if (isVideo) {
      media = (
        <video>
          <source src={`${url}#t=2.0`} type="video/mp4" />
          <track kind="captions" />
        </video>
      );
    } else {
      media = <img src={url} alt="" />;
    }

    return (
      <>
        <button
          type="button"
          className="wrap-media"
          onClick={() => handlePreview(src)}
        >
          {media}
        </button>
        <div className="info">
          <div className="name">{fileName}</div>
          <button
            type="button"
            onClick={() => handleRemoveFile(src.url)}
            className="delete"
          >
            <IconClose />
          </button>
        </div>
      </>
    );
  };

  const isOpenPreview = !isEmpty(pewview);
  return (
    <Style>
      <div className="placeholder">
        <div className="wrap-input">
          <input type="file" multiple onChange={handleInputChange} />
          <Button primary>เลือกรูป/วิดีโอ</Button>
        </div>
        <span>ขนาดไฟล์ไม่เกิน 2GB</span>
      </div>
      <ul>
        {map(fileLoading, (file) => {
          return (
            <li className="loading">
              <div className="wrap-media">
                <div className="loading" />
              </div>
              <div className="name">{get(file, 'name')}</div>
            </li>
          );
        })}
        {map(value, (src) => {
          return <li key={src}>{renderMedia(src)}</li>;
        })}
      </ul>
      <div className={`preview-media ${isOpenPreview ? 'active' : ''}`}>
        <button
          className="close"
          type="button"
          onClick={() => {
            setPewview({});
          }}
        >
          <IconClose />
        </button>
        {isOpenPreview && (
          <div className="content">
            {get(pewview, 'type') === 'video' && (
              <video controls>
                <source src={get(pewview, 'src')} type="video/mp4" />
                <track kind="captions" />
              </video>
            )}
            {get(pewview, 'type') === 'image' && (
              <img src={get(pewview, 'src')} alt="" />
            )}
          </div>
        )}
      </div>
    </Style>
  );
};

UploadFiles.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

UploadFiles.defaultProps = {
  value: '',
  onChange: () => null
};

export default UploadFiles;
