import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { Spin } from 'antd';

const Autocomplete = ({ onSelect }) => {
  const [state, setState] = React.useState({
    address: null
  });

  const handleChange = (address) => {
    setState({ address });
  };

  const handleSelect = async (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        onSelect(latLng);
      })
      .catch((error) => console.error('Error', error));
  };
  return (
    <PlacesAutocomplete
      value={state.address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div>
            <div className="wrap-input">
              <div className="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.85725 4.1072C5.39169 4.1072 3.39296 6.10592 3.39296 8.57148C3.39296 11.037 5.39169 13.0358 7.85725 13.0358C10.3228 13.0358 12.3215 11.037 12.3215 8.57148C12.3215 6.10592 10.3228 4.1072 7.85725 4.1072ZM2.32153 8.57148C2.32153 5.51419 4.79996 3.03577 7.85725 3.03577C10.9145 3.03577 13.393 5.51419 13.393 8.57148C13.393 11.6288 10.9145 14.1072 7.85725 14.1072C4.79996 14.1072 2.32153 11.6288 2.32153 8.57148Z"
                    fill="#393F4D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.0497 12.1211C11.2589 11.9119 11.5981 11.9119 11.8073 12.1211L16.4502 16.764C16.6594 16.9732 16.6594 17.3124 16.4502 17.5216C16.241 17.7308 15.9018 17.7308 15.6926 17.5216L11.0497 12.8788C10.8405 12.6695 10.8405 12.3303 11.0497 12.1211Z"
                    fill="#393F4D"
                  />
                </svg>
              </div>
              <input
                {...getInputProps({
                  placeholder: 'ค้นหาสถานที่',
                  className: 'location-search-input'
                })}
              />
              {loading && (
                <div className="loading">
                  <Spin />
                </div>
              )}
            </div>
            {!loading && size(suggestions) > 0 && (
              <div className="suggestions">
                <ul>
                  {map(suggestions, (suggestion) => (
                    <li
                      {...getSuggestionItemProps(suggestion)}
                      key={get(suggestion, 'placeId')}
                    >
                      <span className="mainText">
                        {get(suggestion, 'formattedSuggestion.mainText')}
                      </span>
                      <span className="secondary-text">
                        {get(suggestion, 'formattedSuggestion.secondaryText')}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

Autocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired
};

Autocomplete.defaultProps = {};

export default Autocomplete;
