import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

import Header from '../../components/Header';
import Country from './Country';
import CompareChart from './CompareChart';
import CustomerChart from './CustomerChart';
import ProblemChart from './ProblemChart';

import useLoading from '../../hooks/loading';

import Style from './style';

const Dashboard = () => {
  const loading = useLoading();
  const [year, setYear] = React.useState(moment().format('YYYY'));

  React.useEffect(() => {
    loading.close();
  }, []); // eslint-disable-line

  const handleBack = () => {
    console.log('handleBack >>>');
  };

  return (
    <Style>
      <Header title="ภาพรวมการดำเนินงาน" onBack={handleBack} />
      <div className="container">
        <div className="wrap-country">
          <Country year={year} onChange={(value) => setYear(value)} />
        </div>

        <div className="wrap-card">
          <ProblemChart year={year} />
        </div>

        <div className="wrap-card">
          <CompareChart year={year} />
        </div>

        <div className="wrap-card">
          <CustomerChart year={year} />
        </div>
      </div>
    </Style>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
