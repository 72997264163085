import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import { useDispatch } from 'react-redux';
import liff from '@line/liff';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import useLoading from '../../../hooks/loading';

import { getConsentForm } from '../../../service';
import { alert, onClosePage } from '../../../utils';

import Style from './style';

const PageCondition = (props) => {
  const { history } = props;
  const loading = useLoading();
  const [state, setState] = React.useState([]);
  const [isAccept, setIsAccept] = React.useState(false);
  const dispatch = useDispatch();

  const initPage = async () => {
    try {
      const res = await getConsentForm();
      setState({
        id: get(res, 'data.id', ''),
        items: get(res, 'data.context.consentItems', [])
      });
    } catch (err) {
      console.log('error s', err);
      alert.error(get(err, 'errorMessage', err));
    }
    loading.close();
  };

  React.useEffect(() => {
    loading.open();
    initPage();
  }, []); // eslint-disable-line

  const handleBack = () => {
    liff.logout();
    onClosePage();
  };

  const handleSubmit = () => {
    loading.open();
    dispatch({
      type: 'CONDITION_ACCEPT',
      id: get(state, 'id'),
      values: map(get(state, 'items', []), (obj) => {
        return obj.key;
      })
    });
    setTimeout(() => {
      history.push('/register/profile');
    }, 300);
  };

  return (
    <Style>
      <Header title="ข้อกำหนดและเงื่อนไข" onBack={handleBack} />
      <div className="container">
        <div className="condition">
          {map(get(state, 'items', []), (obj) => {
            return <p key={get(obj, 'key')}>{get(obj, 'description')}</p>;
          })}
        </div>
        <Checkbox
          checked={isAccept}
          onChange={(e) => {
            setIsAccept(e.target.checked);
          }}
        >
          ยอมรับข้อกำหนดและเงื่อนไข
        </Checkbox>
        <div className="wrap-button">
          <Button primary size="lg" disabled={!isAccept} onClick={handleSubmit}>
            ดำเนินการต่อ
          </Button>
        </div>
      </div>
    </Style>
  );
};

PageCondition.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired
};

PageCondition.defaultProps = {};

export default PageCondition;
