import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import toString from 'lodash/toString';
import axios from 'axios';

import ConfigureStore from '../configureStore';

const createHeader = (customHeaders) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const headers = customHeaders || defaultHeaders;
  const store = ConfigureStore();
  const { user } = store.getState();

  if (typeof user.accessToken !== 'undefined') {
    headers.Authorization = `Bearer ${user.accessToken}`;
  }
  return headers;
};

const fetchWithResponse = (url, options, delay = 0) => {
  const delayStart = Date.now();
  const headers = createHeader();
  return new Promise((resolve, reject) => {
    axios({
      url,
      ...options,
      withCredentials: true,
      headers: {
        ...headers,
        ...get(options, 'headers', {}),
      },
    })
      .then((res) => {
        const time = delay - (Date.now() - delayStart);
        // delay
        setTimeout(
          () => {
            resolve(res.data);
          },
          time > 0 ? time : 0
        );
      })
      .catch((err) => {
        console.error('fetchWithResponse >>', err);
        reject(get(err, 'response.data', err));
      });
  });
};

export const serialize = (values) => {
  if (isEmpty(values)) return '';
  const data = reduce(
    values,
    (pre, value, index) => {
      if (isEmpty(toString(value))) return pre;
      return [
        ...pre,
        `${encodeURIComponent(index)}=${encodeURIComponent(
          toString(value).trim()
        )}`,
      ];
    },
    []
  );
  // console.log('serialize >>', data, values);
  if (isEmpty(data)) return '';
  return `?${data.join('&')}`;
};

const GET = (url, query = {}, delay = 0) => {
  return fetchWithResponse(`${url}${serialize(query)}`, { method: 'GET' }, delay);
};

const POST = (url, body, delay = 0) => {
  return fetchWithResponse(url, { method: 'POST', data: body }, delay);
};
const PATCH = (url, body, delay = 0) => {
  return fetchWithResponse(url, { method: 'PATCH', data: body }, delay);
};

const PUT = (url, body, delay = 0) => {
  return fetchWithResponse(url, { method: 'PUT', data: body }, delay);
};

const DELETE = (url) => {
  return fetchWithResponse(url, { method: 'DELETE' });
};

export { fetchWithResponse, GET, POST, PUT, PATCH, DELETE };
