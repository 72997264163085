import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import MediaPreview from '../../../components/MediaPreview';

import useLoading from '../../../hooks/loading';
import { getThaiDate, alert } from '../../../utils';
import { getIncidentById } from '../../../service';

import Style from './style';

const CaseDetail = (props) => {
  const { history, match } = props;
  const loading = useLoading();
  const roleIds = useSelector(({ user }) => get(user, 'authUser.roleIds', []));
  const [state, setState] = React.useState({});
  const id = get(match, 'params.id');

  const loadData = async () => {
    loading.open();
    try {
      const res = await getIncidentById(id);
      setState(get(res, 'data', {}));
    } catch (err) {
      console.log('loadData >>', err);
      alert.error(get(err, 'errorMessage', err));
    }
    setTimeout(() => {
      loading.close();
    }, 1000);
  };

  React.useEffect(() => {
    loadData();
  }, []); // eslint-disable-line

  const handleBack = () => {
    loading.open();
    // แสดง loading ก่อนค่อยเปลียนหน้า
    setTimeout(() => {
      history.push('/follow-up');
    }, 300);
  };

  const handleAddContent = () => {
    loading.open();
    setTimeout(() => {
      history.push(`/follow-up/${id}/add-content`);
    }, 300);
  };

  const getValue = (key) => {
    const value = get(state, key);
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const getFullname = (keys) => {
    const value = reduce(
      keys,
      (pre, key) => {
        return `${pre} ${get(state, key)}`;
      },
      ''
    ).trim();
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return value;
  };

  const getAddress = (keys) => {
    const value = find(keys, (key) => !isEmpty(get(state, key)));
    if (isEmpty(value)) return 'ไม่มีข้อมูล';
    return get(state, value);
  };

  const getLabelGender = (value, defaultValue = '') => {
    if (/^male$/i.test(value)) return 'ชาย';
    if (/^female$/i.test(value)) return 'หญิง';
    if (/^other$/i.test(value)) return 'อื่นๆ';
    return defaultValue;
  };

  const titleHeader = (
    <div className="wrap-title">
      <span>รายละเอียดเคส</span>
      <button onClick={handleAddContent}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.6 11.04L0 16L4.96 14.4L1.6 11.04Z" fill="#393F4D" />
          <path
            d="M10.5299 2.08406L2.72363 9.89038L6.11769 13.2844L13.924 5.47812L10.5299 2.08406Z"
            fill="#393F4D"
          />
          <path
            d="M15.7599 2.48L13.5199 0.24C13.1999 -0.08 12.7199 -0.08 12.3999 0.24L11.6799 0.96L15.0399 4.32L15.7599 3.6C16.0799 3.28 16.0799 2.8 15.7599 2.48Z"
            fill="#393F4D"
          />
        </svg>
      </button>
    </div>
  );

  const isShowInfo = includes(roleIds, 4) || includes(roleIds, 5);
  return (
    <Style>
      <Header title={titleHeader} onBack={handleBack} />
      <div className="container">
        <ul className="wrap-case">
          {map(get(state, 'actions', []), (dataInfo) => {
            const { toStatus, statusText } = dataInfo;
            const isCurrent = toStatus === get(state, 'status');
            let className = 'active';
            if (isCurrent) className += ' current';
            return (
              <li key={id} className={className.trim()}>
                <div className="wrap-content">
                  <div className="title">{statusText}</div>
                  <p className="content">{get(dataInfo, 'detail')}</p>
                  <p>
                    อัปเดตเคสล่าสุด : {getThaiDate(get(dataInfo, 'updatedAt'))}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
        {isShowInfo && (
          <div className="info">
            <h2>รายละเอียดเหตุการณ์</h2>
            <div className="wrap-item">
              <div>
                <div className="label">รายละเอียด</div>
              </div>
              <div className="value">{getValue('detail')}</div>
            </div>
            <div className="wrap-item">
              <div>
                <div className="label">ภาพที่เกี่ยวข้อง</div>
              </div>
              <div>
                <ul className="images">
                  {map(get(state, 'files'), (file) => (
                    <li key={get(file, 'url', '')}>
                      <MediaPreview
                        className="media-preview"
                        src={get(file, 'url', '')}
                        mimeType={get(file, 'mimeType', '')}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="title">ข้อมูลผู้มีความเสี่ยง</div>
              <div className="wrap-item">
                <div className="label">ชื่อ-นามสกุล</div>
                <div className="value">
                  {getFullname(['firstName', 'lastName'])}
                </div>
              </div>
              <div className="wrap-item">
                <div className="label">เพศ</div>
                <div className="value">
                  {getLabelGender(get(state, 'gender'), 'ไม่มีข้อมูล')}
                </div>
              </div>
              <div className="wrap-item">
                <div className="label">เบอร์โทรศัพท์</div>
                <div className="value">{getValue('telephone')}</div>
              </div>
              <div className="wrap-item">
                <div className="label">ที่อยู่</div>
                <div className="value">
                  {getAddress(['address', 'provinceName'])}
                </div>
              </div>
              <div className="wrap-item">
                <div className="label">Map</div>
                <div className="value">
                  {get(state, 'latitude') && get(state, 'longitude') ? (
                    <a
                      href={`https://maps.google.com/?q=${get(
                        state,
                        'latitude'
                      )},${get(state, 'longitude')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="sm" primary>
                        Go to Map
                      </Button>
                    </a>
                  ) : (
                    'ไม่มีข้อมูล'
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="title">ข้อมูลผู้มีความเสี่ยง</div>
              <div className="wrap-item">
                <div className="label">ชื่อ-นามสกุล</div>
                <div className="value">
                  {getFullname(['contactFirstName', 'contactLastName'])}
                </div>
              </div>
              <div className="wrap-item">
                <div className="label">เพศ</div>
                <div className="value">
                  {getLabelGender(get(state, 'contactGender'), 'ไม่มีข้อมูล')}
                </div>
              </div>
              <div className="wrap-item">
                <div className="label">เบอร์โทรศัพท์</div>
                <div className="value">{getValue('contactTelephone')}</div>
              </div>
              <div className="wrap-item">
                <div className="label">ความเกี่ยวข้อง</div>
                <div className="value">{getValue('contactRelation')}</div>
              </div>
              <div className="wrap-item">
                <div className="label">ที่อยู่</div>
                <div className="value">{getValue('contactAddress')}</div>
              </div>
              <div className="wrap-item">
                <div className="label">Map</div>
                <div className="value">
                  {get(state, 'contactLatitude') && get(state, 'contactLongitude') ? (
                    <a
                      href={`https://maps.google.com/?q=${get(
                        state,
                        'contactLatitude'
                      )},${get(state, 'contactLongitude')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button size="sm" primary>
                        Go to Map
                      </Button>
                    </a>
                  ) : (
                    'ไม่มีข้อมูล'
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Style>
  );
};

CaseDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape().isRequired
};

CaseDetail.defaultProps = {};

export default CaseDetail;
