import moment from 'moment';
import get from 'lodash/get';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import { toast } from 'react-toastify';

const getErrorMessage = (err) => {
  let message = err;
  if (!isString(err)) {
    message = toString(err);
  }
  return message;
};

export const alert = {
  info: (err) => toast.info(getErrorMessage(err)),
  success: (err) => toast.success(getErrorMessage(err)),
  warn: (err) => toast.warn(getErrorMessage(err)),
  error: (err) => toast.error(getErrorMessage(err))
};

export { default as connectRedux } from './connectRedux';

export const getThaiDate = (date, format = 'DD MMMM YYYY เวลา HH:mm น.') => {
  moment.updateLocale('th', {
    months: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม'
    ]
  });
  return moment(date).locale('th').add(543, 'year').format(format);
};

export const dateToFormat = (value, defaultValue = undefined) => {
  if (get(value, 'format')) return value.format();
  return defaultValue;
};

export const onClosePage = () => {
  window.opener = null;
  window.open('', '_self');
  window.close();
};

export const getMonthName = (index = 0) => {
  return get(
    [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    index,
    ''
  );
};
